function splitTask() {
  return new Promise(res => setTimeout(() => res()))
}
export const comps = {
  checkoutTopNotice: () => import(/* webpackChunkName: "checkout_top_part" */'../page_tpls/checkoutTopNotice.vue'),
  addressOptions: () => import(/* webpackChunkName: "checkout_top_part" */'../page_tpls/address_options.vue'),
  shoppingMall: () => import(/* webpackChunkName: "checkout_top_part" */'../page_tpls/shopping_mall.vue'),
  shippingOptions: () => import(/* webpackChunkName: "checkout_top_part" */'public/src/pages/checkout/vue_tpls/shipping_method/ShippingOptions.vue'),
  shoppingBagOptions: () => import(/* webpackChunkName: "checkout_top_part" */'../page_tpls/shopping_bag_options.vue'),
  couponOptions: () => import(/* webpackChunkName: "checkout_top_part" */'../page_tpls/coupon_options.vue'),
  CheckoutFooter: () => import(/* webpackChunkName: "checkout_top_part" */'../page_tpls/checkout_footer.vue'),

}
export function loadComps(params) {
  if (params === 'prefetch') return Object.values(comps).map(fn => fn())

  return Object.keys(comps).reduce((curr, next) => {
    curr[next] = () => splitTask().then(() => comps[next]())
    return curr
  }, {})
}
