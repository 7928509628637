<template>
  <div id="checkout-app">
    <div class="checkout-warp checkout-wrap-effiency">
      <CheckoutHeader />
      <div class="mshe-container j-mshe-container">
        <div
          v-expose="{
            id: '1-11-1-112',
            data: viewShippingAnalysis
          }"
          class="mshe-top-pad c-checkout-page j-checkout-container"
        >
          <checkoutTopNotice
            class="checkout-notice"
            :class="{ 'is-ssr': isSSR && !isClient }"
          />
          
          <address-options
            ref="addressOptionsVue"
            @emit-store-address="emitStoreAddress"
            @edit-default-address="editDefaultAddress"
            @add-default-address="handleAddressAdd({ noHash: true })"
          />

          <shipping-options
            v-if="!shoppingBagPosition"
            :mall-code="firstMallList.mall_code || '1'"
            :shipping-methods="firstMallList.shipping_methods || []"
            :carts-data="cartsData"
            :quick-ship-info="quickShipInfo"
            @insurance-check-opt="insuranceCheckOpt"
            @handle-shipping-select="handleShippingSelect"
          />

          <template
            v-if="shoppingBagPosition && mallCartsList && mallCartsList.length"
          >
            <shopping-mall
              v-for="(mall, index) in mallCartsList"
              :key="`${mall[0].mall_code}_${index}`"
              :mall-code="mall[0].mall_code"
              :carts-data="mall"
              @insuranceCheckOpt="insuranceCheckOpt"
              @handleShippingSelect="handleShippingSelect"
            />
          </template>

          <!-- 随手购模块 支付方式下方1 -->
          <ClientOnly>
            <TogetherBuy v-if="togetherBuyLocation == 1 && showTogetherBuy" />
          </ClientOnly>

          <payment-options
            v-if="!isFooterSelectPayment"
            ref="paymentOptions"
            class="payment-option-effiency checkout-scroll__payment"
            :payment-description-abt="true"
            page-name="checkout"
            :google-valid="googleValid"
            :language="language"
            :checkout="checkout"
            :payment-list="paymentList"
            :cod-display="codDisplay"
            :not-support-cod-reason="notSupportCodReason"
            :price="price"
            :status="status"
            :payment-option="paymentOption"
            :banks-config="banksConfig"
            :no-select-payment="noSelectPayment"
            :click-payment="clickPayment"
            :handle-start-select-bank="handleStartSelectBank"
            :show-mask-tips="showMaskTips"
            :need-pay-inline-payment-methods="needPayInlinePaymentMethods"
            :show-widget-form="showWidgetForm"
            :ppgv-account-info="ppgvAccountInfo"
            :after-pay-account-info="afterPayAccountInfo"
            :paypal-ga-vault="paypalGaVault"
            :afterPayVault="afterPayVault"
            :iconPrefix="iconPrefix"
            :locals="locals"
            :default-select-token-pay="defaultSelectTokenPay"
            :handle-select-token-pay="handleSelectTokenPay"
            :payment-logo-list="paymentLogoList"
            @show-paypalga-type="showPaypalGaType"
            @confirm-ppgv-type="confirmPPGVType"
            @confirm-afterpay-type="confirmAfterPayType"
            @unfold="unfold"
            @payment-options-oper="handlePaymentOptionsOper"
          />

          <ClientOnly>
            <div>
              <template v-if="isChangePrimeSaverLocation">
                <!-- 付费会员（随单购等） -->
                <prime-options-wrap :paymentMethodsProps="paymentMethodsProps" />
              </template>
              <template v-else>
                <!-- 超省卡 -->
                <xtraOptions :paymentMethodsProps="paymentMethodsProps" />
              </template>
              <!-- 随手购模块 支付方式下方2 -->
              <TogetherBuy v-if="(togetherBuyLocation == 2 && showTogetherBuy)" />
            </div>
          </ClientOnly>

          <div class="checkout-scroll__saved">
            <!-- 优惠卷输入、钱包、礼品卡、积分模块 -->
            <coupon-options
              ref="couponOptions"
              :check-coupon-price="checkCouponPrice"
            />

            <ClientOnly>
              <div v-if="isClient">
                <template v-if="isChangePrimeSaverLocation">
                  <!-- 超省卡 -->
                  <xtraOptions :paymentMethodsProps="paymentMethodsProps" />
                </template>
                <template v-else>
                  <!-- 付费会员（随单购等） -->
                  <prime-options-wrap :paymentMethodsProps="paymentMethodsProps" />
                </template>

                <!-- 随手购模块 虚拟下方3 -->
                <TogetherBuy v-if="togetherBuyLocation == 3 && showTogetherBuy" />

                <!-- 单mall样式 -->
                <shopping-bag-options
                  v-if="!shoppingBagPosition"
                  class="single-bag"
                  :carts-info="cartsData"
                  :carts-mall-info="cartsMallInfo"
                  :quickShipInfo="quickShipInfo"
                  :mall-code="firstMallList.mall_code || '1'"
                  @insurance-check-opt="insuranceCheckOpt"
                />

                <order-summary :pre-pay-un-pay-params="prePayUnPayParams" />
              </div>
            </ClientOnly>
          </div>

          <CheckoutFooter
            ref="checkoutFooterRef"
            :paypal-btn-show="paypalBtnShow"
            :paypal-venmo-btn-show="paypalVenmoBtnShow"
            :status="status"
            :banks-config="banksConfig"
            :payment-methods-props="paymentMethodsProps"
            :is-footer-select-payment="isFooterSelectPayment"
            :paypal-ga-vault="paypalGaVault"
            :pre-pay-un-pay-params="prePayUnPayParams"
            @create-order="createOrder"
            @payment-options-oper="handlePaymentOptionsOper"
            @add-default-address="handleAddressAdd({ noHash: true })"
          />
        </div>
      </div>

      <ClientOnly>
        <div v-if="isClient">
          <s-loading
            type="curpage"
            :show="
              (loadingShow || loading || loadingParam.loading) &&
                !createOrderLoading
            "
            :new-page-delay="false"
            :mask-transparent="true"
          />

          <checkout-mask-dialog
            v-if="blackMask.isLoaded"
            :black-mask="blackMask"
            :has-guide="hasGuide"
            :language="language"
            :billno-list="billnoList"
          />
          <!-- 钱包&礼品卡使用不可用商品提示滑出层 -->
          <freez-tips-and-goods
            v-if="asyncComponentLoadedStatus.freezTipsAndGoodsIsLoaded"
            @apply-gift-card-go-back="applyGiftCardGoBack"
          />

          <!-- 商品 滑出层-->
          <shopping-bag-list
            v-if="asyncComponentLoadedStatus.shoppingBagModalIsLoaded"
          />

          <!-- giftcard滑出层 -->
          <gift-card
            v-if="asyncComponentLoadedStatus.giftCardIsLoaded"
            ref="giftCardPage"
            :cardusedlist="giftcard.giftcardUsedList"
            :page-status="pageStatus"
            @apply-gift-card-go-back="applyGiftCardGoBack"
            @show-coupon-tips="showCouponTips"
          />

          <!-- 优惠券 滑出层-->
          <my-coupon-list
            v-if="asyncComponentLoadedStatus.myCouponListIsLoaded"
            :couponcodeval="coupon.val"
            :errinfo="coupon.err"
          />

          <!-- 优惠券 隐私政策 -->
          <my-coupon-privacy-item
            v-if="asyncComponentLoadedStatus.myCouponPrivacyItemIsLoaded"
          />
          <!-- 无货提示 滑出层-->
          <sold-out-vue
            v-if="asyncComponentLoadedStatus.soldOutIsLoaded"
            :checkout="checkout"
            :isBatchHandle="false"
            @delete="soldOutCartsDelete"
          />
          <!-- 南非特殊商品下单限制 滑出层 -->
          <special-goods-drawer
            v-if="asyncComponentLoadedStatus.specialGoodsIsLoaded"
            @move="handleUpdateCheckoutStatus"
          />

          <!-- 用券赠礼 滑出层-->
          <coupon-gifts-add-item
            v-if="asyncComponentLoadedStatus.couponGiftAddItemIsLoaded"
            :coupon-gift="couponGift"
          />

          <address-common
            v-if="asyncComponentLoadedStatus.addressCommonIsLoaded"
            ref="addressCom"
            @emit-store-address="emitStoreAddress"
            @update-payment="updatePayment"
            @address-change="handleAddressChanged"
          />

          <!-- 强制密码修改 -->
          <s-drawer
            v-ada-modal="riskDialogFocus"
            :visible.sync="showRiskDialog"
            :immediately-render="true"
            @close="closeRiskDialog"
          >
            <template slot="top">
              <span
                ref="riskTitle"
                tabindex="0"
              >
                {{ language.SHEIN_KEY_PWA_17711 }}
              </span>
            </template>
            <div style="padding-bottom: 2rem">
              <riskconfirm
                v-if="asyncComponentLoadedStatus.loginConfirmIsLoaded"
                ref="mainLogin"
                :page-type="11"
                :is-dialog="true"
                @verify-suc-callback="loginModalSucCb"
                @confirm-callback="riskConfirmCb"
              />
            </div>
          </s-drawer>

          <!-- 支付方式优惠券可使用的支付方式列表 -->
          <payment-coupon
            v-if="asyncComponentLoadedStatus.paymentCouponIsLoaded"
          />

          <ShippingFeeInfo
            v-if="asyncComponentLoadedStatus.shippingFeeIsLoaded"
            ref="shippingFeeDrawer"
            :show-shipping-fee-drawer="showShippingFeeDrawer"
            :shipping-fee-rule-info="shippingFeeRuleInfo"
            :is-suggested="isSuggested"
            @closeShippingFee="closeShippingFee"
            @closeLoading="closeLoading"
          />

          <ShoppingBagTax v-if="asyncComponentLoadedStatus.bagTaxIsLoaded" />

          <!-- 谷歌pay弹窗 -->
          <googlepay-dialog
            v-if="isClient"
            :visible.sync="googlePay.show"
            @manual-close="cancelGoogle(false)"
          />

          <pay-result-tips
            v-if="showPayResult"
            :show="showPayResult"
            :language="language"
          />
          <pop-privacy
            v-if="mixPrivacy.show"
            :show="mixPrivacy.show"
            :lang-text="language"
            :location-id="
              checkout.default_address && checkout.default_address.countryId
            "
            @agreen="handleMixAgreen"
            @close="handleMixClose"
          />
          <ShippingDayPercent
            v-if="asyncComponentLoadedStatus.shippingDayPercentIsLoaded"
            ref="shippingDayPercent"
            :language="language"
            :shipping-day-percent-methods="shipPercentMethods"
            :show-shipping-day-percent="showShippingDayPercent"
            :default-index="dayPercentInx"
            @closePercentList="closePercentList"
            @closeLoading="closeLoading"
          />
          <!-- 卡bin优惠文章展示 -->
          <CardBinDiscountArticle
            v-if="asyncComponentLoadedStatus.cardBinDiscountArticleIsLoaded"
            :visible.sync="visibleCardBinDiscountArticle"
            @closeLoading="closeLoading"
          />

          <checkoutDialog
            v-if="asyncComponentLoadedStatus.checkoutDialogIsLoaded"
            :discount-limit-ids="discountLimitIds"
            :flash-limit-ids="flashLimitIds"
            :gift-card-limit="giftCardLimit"
            :message-box="messageBox"
            :order-unpaid="orderUnpaid"
            :apple-pay="applePay"
            :qiwi="qiwi"
            :email-tips="emailTips"
            :is-show-coupon-tips="isShowCouponTips"
            :mbway-info="mbwayInfo"
            @giftCardRemove="giftCardRemove"
            @giftCardConfirm="giftCardConfirm"
            @closeOrderUnpaid="orderUnpaid = false"
            @clickQiwiPay="clickQiwiPay"
            @qiwiPayPhone="qiwiPayPhone = ''"
            @createOrderFn="createOrderFn"
            @closeCoupon="closeCoupon"
            @confirmMbwayPhone="confirmMbwayPhone"
            @confirmNotUseCoupon="confirmNotUseCoupon"
          />
          <address-list-drawer
            v-if="asyncComponentLoadedStatus.addressListPageIsLoaded"
            @selected="handleAddressSelectd"
            @edit-address="handleAddressEdit"
            @add-new-address="handleAddressAdd"
          />
          <!-- 银行列表选择 -->
          <ChooseBankDrawer
            :visible.sync="bankDrawerConfig.visible"
            :language="language"
            :loading="loadingParam.loading"
            :bank-list="bankDrawerConfig.bankList"
            :default-code="bankDrawerConfig.defaultCode"
            @select="handleBankSelectInVm"
          />
          <!-- 南非capitec信息采集弹框/dlocal-pse支付方式email信息收集弹框 -->
          <bankCapitecDialog 
            :visible.sync="capitecDialog.visible"
            :language="language"
            :capitecDialog="capitecDialog"
            @event="handleCapitecDialogEventInVm"
          />
          <!-- 下单限制规则前置 - 修改地址 -->
          <ShippingAddressLimit
            v-if="asyncComponentLoadedStatus.shippingLimitAddressIsLoaded"
            @edit-address="editDefaultAddress"
            @edit-store-address="handleEditStoreAddress"
            @add-address="handleAddressAdd({ noHash: true })"
            @select-address="handleAddressSelect"
            @close="handleShippingLimitAddressClose"
          />
          <!-- 宅店配运输方式切换互斥弹窗 -->
          <MultiMallAddressMutex
            v-if="asyncComponentLoadedStatus.shippingTypeDialogIsLoaded"
            @cancel="cancelChangeShippingType"
            @change="changeShippingType"
          />
          <!-- 下单限制规则前置 - 修改商品 -->
          <ShippingLimitGoods
            :isBatchHandle="false"
            v-if="asyncComponentLoadedStatus.shippingLimitGoodsIsLoaded"
          />
          <!-- 下单限制规则前置 - 修改店配地址 -->
          <StoreAddressLimit
            v-if="asyncComponentLoadedStatus.shippingLimitStoreIsLoaded"
            @edit-store-address="handleEditStoreAddress"
            @handle-shipping-select="handleShippingSelect"
          />

          <FpxTipsDialog
            v-bind="fpxTipsDialogProps"
            @confirm="confirmFpxTips"
            @cancel="cancelFpxTips"
          />

          <ShoppingBagRetainDialog
            v-if="asyncComponentLoadedStatus.shoppingBagRetainDialogIsLoaded"
          />

          <QuickshipRecheckDialog
            v-if="asyncComponentLoadedStatus.showRecheckQuickshipIsLoaded"
          />
          <TokenPayComps
            v-if="asyncComponentLoadedStatus.prePayCompsIsLoaded"
            :visible-options="challengeModalOptions"
          />

          <bin-discount-dialog 
            :language="language" 
            @create-order="createOrder"
          />

          <HomogenizationDrawer
            :visible="isShowHomogenizationDrawer"
            :language="language"
            @change-visible="changeHomogenizationDrawerVisible(false)"
          />
          <bin-coupons-drawer 
            :language="language" 
            @newApplyCoupon="newApplyCoupon"
          />
          <bin-coupons-normal-drawer
            :language="language"  
            :paymentMethodsProps="paymentMethodsProps"
            @newApplyCoupon="newApplyCoupon"
          />
          <!-- 卡分期支付前置 -->
          <lazy-mount>
            <PrePayModal
              ref="prePayModalRef"
              :visible.sync="visiblePrePay"
              @actively-close="handlePrePayActiveClose"
            >
              <template slot="footer">
                <div>
                  <transition name="detail">
                    <PriceDetailDrawer 
                      :language="language"
                      :is-pre-pay="true"
                      :pre-pay-un-pay-params="prePayUnPayParams"
                      class="prePay-price-detail"
                    />
                  </transition>
                  <FooterSummaryEffiency
                    ref="footerSummaryEfficiency"
                    class="j-summary-effiency prePay-footer-summary"
                    :check-show-total-fee="checkShowTotalFee"
                    :gov-tax-price="govTaxPrice"
                    :is-show-ca-g-s-t="isShowCaGST"
                    :is-pre-pay="true"
                    :pre-pay-un-pay-params="prePayUnPayParams"
                    @totalFeeMaskTips="showMaskTips(language.SHEIN_KEY_PWA_18954)"
                    @clickMaskGst="showMaskTips(language.SHEIN_KEY_PWA_20400)"
                    @toggleTotal="toggleTotal"
                  >
                    <SButton
                      :type="['primary', 'H72PX']"
                      width="100%"
                      @click="handlePrePayForCheckout"
                    >
                      <span v-html="prePayPlaceOrderBtnText"></span>
                    </SButton>
                  </FooterSummaryEffiency>

                  <transition name="mask">
                    <div
                      v-if="isShowPriceDetail"
                      class="price-detail-mask"
                      @click="handlerChangePriceDetail"
                    ></div>
                  </transition>
                </div>
              </template>
            </PrePayModal>
          </lazy-mount>
          <lazy-mount>
            <FailGuideModal
              :visible.sync="prePayFailGuide.visible"
              :text="prePayFailGuide.failText"
              :type="prePayFailGuide.type"
              :language="prePayLanguage"
              :close-cb="prePayFailGuide.closeCb"
              :confirm-cb="prePayFailGuide.confirmCb"
              :has-guide="prePayFailGuide.hasGuide"
            />
          </lazy-mount>
          <!-- 单后场景 - 展示了卡bin优惠但冻结失败弹窗提示 -->
          <lazy-mount>
            <BinDiscountFreezeFailTips
              :visible.sync="prePayBinFreezeFailConfig.visible"
              :language="language"
              @cancel="prePayBinFreezeFailConfig.closeCb"
              @skip-freeze-to-pay="prePayBinFreezeFailConfig.confirmCb"
            />
          </lazy-mount>
          <installment-list-drawer 
            :language="language" 
            @create-order="createOrder"
            @payment-options-oper="handlePaymentOptionsOper"
          />
          <!-- 卡分期token弹窗 -->
          <installment-token-drawer
            :language="language"
            @use-new-card="handleUsePrePay"
          />
          <CvvDrawer 
            :language="language"
            :is-show-cvv-drawer="isShowCvvDrawer"
            :default-select-token-pay="defaultSelectTokenPay"
            @ChangeIsShowCvvDrawer="ChangeIsShowCvvDrawer"
            @cvvResult="handleCvvResult"
          />
          <ShoppingBagTaxDialog 
            v-if="showTaxHint"
            @createOrder="createOrder"
          />
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script>
/* globals gbCheckoutSsrData,shem_caculate_data,ApplePaySession, Klarna */
import { stateFactory } from '../product_app/store/modules/checkout'

import ClientOnly from 'vue-client-only'
import Vue from 'vue'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { template as _commonTemplate, getLocalStorage, getQueryString, parseQueryString } from '@shein/common-function'
import { CustomQuota } from 'public/src/js/utils/customQuota'
import checkoutMixin from './mixins/checkout-mixin'
import payInlineMixin from './mixins/payInlineMixin'
import couponMixin from './mixins/couponMixin'
import priceMixin from './mixins/priceMixin'
import fpxTipsMixin from './mixins/fpxTipsMixin'
import { useSendSingleMsg } from 'public/src/pages/checkout/hooks/index.js'
import paymentOptionsMixin from 'public/src/pages/checkout/page_tpls/payment_options_mixin.js'

Vue.component('ClientOnly', ClientOnly)
import {
  TitleNav,
  TitleNavItem,
  Button,
  ButtonGroup,
  Dialog,
  ButtonGroupItem,
  Drawer,
  Alert,
  Checkbox,
  Radio,
  Field,
  Sticky,
  Label,
  LazyMount
} from '@shein/sui-mobile'
Vue.use(Button)
Vue.use(Dialog)
Vue.use(ButtonGroup)
Vue.use(ButtonGroupItem)
Vue.use(Drawer)
Vue.use(Alert)
Vue.use(TitleNav)
Vue.use(TitleNavItem)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Field)
Vue.use(Sticky)
Vue.use(Label)

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { abtservice } from 'public/src/services/abt'
import {
  handleSwitchCurrency,
  judgeActivityIsExist,
  publishCartCheckout,
} from './utils.js'
import schttp from 'public/src/services/schttp'

import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import applePayConfig from 'public/src/pages/common/apple_pay'
import {
  couponCodeTips,
  handleMallShippingAnalysisParam,
  handleMallShippingAnalysis,
  handleCalcuMallParam,
  handleMallInsuranceChange,
  useTransportCache,
  handleMallShippingInfo,
  initMallShipIndex,
  isShipOrderIntercept,
  updateMallInsuranceCheck,
  handleMallShipIndex,
  isShowMultiMallAddressMutex,
  handleCurrShipSwitchQsFlag,
  handleNextShippingMethod,
  filterPromitionGoods
} from 'public/src/pages/checkout/config/tools.js'

import analysisCheckoutEvent from './components/analysis_new'
import { googlePayMethods } from 'public/src/pages/checkout/components/config'
import checkoutHeader from './vue_tpls/checkoutHeader.vue'
import paymentOptions from './page_tpls/payment_options_new.vue'
import { comps } from './components/fs_async_comps'
import usePaymentAbt from 'public/src/pages/checkout/hooks/usePaymentAbt'
import useTokenPay from '@/public/src/pages/common/handlePay/hooks/useTokenPay.js'
import { replacePaySuccessReferer } from '@/public/src/pages/checkout/components/tools.js'
import { checkoutReport as reportBankDialog }  from 'public/src/pages/checkout/tools/report_bank_dialog.js'
import useChallengeModal from '@/public/src/pages/common/handlePay/hooks/useChallengeModal.js'
import usePrePay from '@/public/src/pages/components/prePay/hooks/usePrePay.js'
import useCardPayForCheckout from '@/public/src/pages/components/prePay/hooks/useCardPayForCheckout.js'
import useDdc from '@/public/src/pages/common/handlePay/hooks/useDdc.js'

import { fetchPayDomainLogoApi } from 'public/src/services/api/pay/checkout.js'
import { fetchCheckStoreAddressApi } from 'public/src/services/api/pay/checkout.js'

import { SIMetric } from 'public/src/pages/common/monitor/index.js'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

const { lang, host, langPath, PUBLIC_CDN, SiteUID } = gbCommonInfo


if (typeof window != 'undefined' && typeof gbCheckoutSsrData === 'undefined') {
  window.gbCheckoutSsrData = {
    language: {},
    checkout: {}
  }
}

let SaPageInfo = {
  page_id: 11,
  page_name: 'page_checkout',
  page_param: {
    page_from: ''
  },
  start_time: new Date().getTime()
}

if (typeof window != 'undefined') window.shem_caculate_data = {}

const CustomQuotaInstance = CustomQuota.getInstance()
CustomQuotaInstance
  .setPathName('m_checkout')
  .setQuotaReg({
    reqRegAry: ['api/checkout/mallOrder/create', 'devices/v3/profile/web'],
    jsRegAry: [/assets\/checkout/],
    cssRegAry: [/checkout/, /theme/, /shein/]
  })

export default {
  name: 'CheckoutContainer',
  components: {
    ...comps,
    LazyMount,
    CheckoutHeader: checkoutHeader,
    paymentOptions,
    orderSummary: () =>
      import(
        /* webpackChunkName: "checkout-order-summary" */ './page_tpls/order_summary.vue'
      ),
    addressListDrawer: () =>
      import(
        /* webpackChunkName: "checkout-address-list-drawer" */ './vue_tpls/address_list_drawer'
      ),
    myCouponList: () =>
      import(
        /* webpackChunkName: "checkout-coupon-list-giftcard" */ './vue_tpls/my_coupon_list.vue'
      ),
    giftCard: () =>
      import(
        /* webpackChunkName: "checkout-coupon-list-giftcard" */ './vue_tpls/giftcard.vue'
      ),
    myCouponPrivacyItem: () =>
      import(
        /* webpackChunkName: "checkout-coupon-privacy" */ './vue_tpls/my_coupon_privacy_item.vue'
      ),
    soldOutVue: () =>
      import(
        /* webpackChunkName: "checkout-sold-out" */ './vue_tpls/sold_out.vue'
      ),
    riskconfirm: () =>
      import(
        /* webpackChunkName: "checkout-loginconfirm" */ './../user/child_pages/dashboard/risk_confirm.vue'
      ),
    shoppingBagList: () =>
      import(
        /* webpackChunkName: "checkout-cart-list" */ './vue_tpls/shopping_bag_list.vue'
      ),
    paymentCoupon: () =>
      import(
        /* webpackChunkName: "checkout-payment-coupon" */ './vue_tpls/paymentCoupon.vue'
      ),
    SpecialGoodsDrawer: () =>
      import(
        /* webpackChunkName: "checkout-special-goods" */ './vue_tpls/SpecialGoodsDrawer.vue'
      ),
    payResultTips: () =>
      import(
        /* webpackChunkName: "checkout-pay-module" */ 'public/src/pages/common/orderLogic/payResultTips.vue'
      ),
    freezTipsAndGoods: () =>
      import(
        /* webpackChunkName: "checkout-freez-tips-and-goods" */ './vue_tpls/limit_goods/freezTipsAndGoods.vue'
      ),
    primeOptionsWrap: () =>
      import(
        /* webpackChunkName: "checkout-prime-options" */ './page_tpls/prime_options_wrap.vue'
      ),
    googlepayDialog: () => import('./page_tpls/googlepay_dialog.vue'),
    ShippingDayPercent: () =>
      import(
        /* webpackChunkName: "checkout-shipping-day-percent" */ './vue_tpls/shipping_day_percent'
      ),
    CouponGiftsAddItem: () =>
      import(
        /* webpackChunkName: "checkout-coupon-gift" */ './vue_tpls/coupon_gift/CouponGiftsAddItem.vue'
      ),
    PopPrivacy: () =>
      import(
        /* webpackChunkName: "checkout-privacy" */ './vue_tpls/pop_privacy.vue'
      ),
    addressCommon: () =>
      import(
        /* webpackChunkName: "checkout-address" */ './page_tpls/address_common.vue'
      ),
    checkoutMaskDialog: () =>
      import(
        /* webpackChunkName: "checkout-common-dialog" */ './vue_tpls/dialog/checkoutMaskDialog.vue'
      ),
    checkoutDialog: () =>
      import(
        /* webpackChunkName: "checkout-dialog" */ './vue_tpls/dialog/checkoutDialog.vue'
      ),

    CardBinDiscountArticle: () =>
      import(
        /* webpackChunkName: "CardBinDiscountArticle_Checkout" */ 'public/src/pages/components/CardBinDiscountArticle.vue'
      ),
    ShippingFeeInfo: () =>
      import(
        /* webpackChunkName: "checkout-shipping-fee" */ './vue_tpls/shipping_fee/index.vue'
      ),
    ChooseBankDrawer: () =>
      import(
        /* webpackChunkName: "ChooseBankDrawer_Checkout" */ './vue_tpls/ChooseBankDrawer.vue'
      ),
    bankCapitecDialog: () =>
      import(
        /* webpackChunkName: "bank_capitec_dialog" */ './vue_tpls/dialog/bank_capitec_dialog.vue'
      ),
    xtraOptions: () =>
      import(
        /* webpackChunkName: "checkout-xtra-options" */ './vue_tpls/xtra_options/index.vue'
      ),
    ShoppingBagTax: () =>
      import(
        /* webpackChunkName: "checkout-shopping-tax" */ './vue_tpls/shopping_bag_tax/index.vue'
      ),
    ShippingAddressLimit: () =>
      import(
        /* webpackChunkName: "checkout-shipping-address-limit" */ './vue_tpls/shipping_order_intercept/ShippingAddressLimit.vue'
      ),
    MultiMallAddressMutex: () =>
      import(
        /* webpackChunkName: "checkout-multi-mall-address-mutex" */ '@/public/src/pages/checkout/vue_tpls/MultiMallAddressMutex.vue'
      ),
    ShippingLimitGoods: () =>
      import(
        /* webpackChunkName: "checkout-shipping-limit-goods" */ '@/public/src/pages/checkout/vue_tpls/shipping_order_intercept/LimitGoods.vue'
      ),
    StoreAddressLimit: () =>
      import(
        /* webpackChunkName: "checkout-shipping-address-limit" */ './vue_tpls/shipping_order_intercept/StoreAddressLimit.vue'
      ),
    TogetherBuy: () =>
      import(
        /* webpackChunkName: 'together-buy' */ './vue_tpls/together_buy/container.vue'
      ),
    ShoppingBagRetainDialog: () =>
      import(
        /* webpackChunkName: "checkout-shopping-bag-retain" */ './vue_tpls/shopping_bag/retain_dialog/Index.vue'
      ),
    QuickshipRecheckDialog: () =>
      import(
        /* webpackChunkName: "checkout-shopping-bag-quickship" */ './vue_tpls/shopping_bag/QuickshipRecheckDialog.vue'
      ),
    TokenPayComps: () =>
      import(
        /* webpackChunkName: "token-pay-comps" */ 'public/src/pages/common/handlePay/components/TokenPayComps.vue'
      ),
    BinDiscountDialog: () => 
      import('public/src/pages/checkout/page_tpls/token_pay/bin_discount_dialog.vue'),
    HomogenizationDrawer: () => import('public/src/pages/checkout/page_tpls/pay_homogenization/homogenization_drawer.vue'),
    BinCouponsDrawer: () => import('public/src/pages/checkout/page_tpls/token_pay/bin_coupons_drawer.vue'),
    BinCouponsNormalDrawer: () => import('public/src/pages/checkout/page_tpls/token_pay/bin_coupons_normal_drawer.vue'),
    PrePayModal: () => import(/* webpackChunkName: "pre-pay-modal" */'public/src/pages/components/prePay/index.vue'),
    InstallmentListDrawer: () => import('public/src/pages/checkout/page_tpls/pay_installment/installment_list_drawer.vue'),
    InstallmentTokenDrawer: () => import('public/src/pages/checkout/page_tpls/pay_installment/installment_token_drawer.vue'),
    CvvDrawer: () => import('public/src/pages/checkout/page_tpls/cvv_drawer.vue'),
    ShoppingBagTaxDialog: () => import('public/src/pages/checkout/vue_tpls/shopping_bag_tax/dialog.vue'),
    FooterSummaryEffiency: () => import('public/src/pages/checkout/page_tpls/footer_summary_effiency.vue'),
    PriceDetailDrawer: () => import('public/src/pages/checkout/page_tpls/price_detail_drawer.vue'),
    FailGuideModal: () => import(/* webpackChunkName: "pre-pay-modal" */'public/src/pages/components/prePay/components/FailGuideModal.vue'),
    BinDiscountFreezeFailTips: () => import('public/src/pages/components/prePay/components/BinDiscountFreezeFailTips.vue')
  },
  mixins: [
    checkoutMixin,
    payInlineMixin,
    couponMixin,
    priceMixin,
    fpxTipsMixin,
    paymentOptionsMixin
  ],
  setup() {
    const { paymentCustomizeFlow, codFreeShow, applePayCashShow } = usePaymentAbt()
    useSendSingleMsg(daEventCenter)

    const { challengeModalOptions } = useChallengeModal()

    const { 
      ddcInfo,
      selectedTokenPayInfo,
      initTokenPayConfig,
      setTokenPayInfo,
      handleTokenPay, 
      normalTokenPayResultHandle,
      setTokenPayCvv
    } = useTokenPay({
      scene: 'Checkout',
      challengeModalOptions
    })

    const {
      initPayJsonWebToken,
      sendDdc,
    } = useDdc()

    const { 
      visiblePrePay,
      prePayModalRef,
      ddcFormParams,
      prePayUnPayParams,
      prePayFailGuide,
      prePayLanguage,
      prePayCheckoutInfo,
      prePayOrderInfo,
      cardBinFreezeParams,
      prePayBinFreezeFailConfig,
      pageReadyTime,
      clickContinueResultInfo,
      // virtualTokenInfo,
      formatPrePayCheckoutInfo,
      updatePrePaySetCheckoutInfo,
      prePayEventBus,
      handlePrePayForCheckout,
      getInstallmentInfo: prePayGetInstallmentInfo,
      prePayMergeState,
      prePayUpdateState,
      checkPrePayParamsChange,
      handleQueryUnPayPreRoutingInfo,
      changeCardBinDiscountInfoData,
      resetPrePayCheckoutInfo,
      reportClickContinueResult,
      // appendVirtualTokenInfo,
      // resetVirtualTokenInfo
    } = usePrePay({
      scene: 'Checkout'
    })

    const {
      initPrePayForCheckoutConfig,
      setPrePayCheckoutInfo,
      handlePayForPreCheckout,
    } = useCardPayForCheckout({
      scene: 'Checkout',
      challengeModalOptions
    })

    return {
      ddcInfo,
      selectedTokenPayInfo,
      paymentCustomizeFlow,
      codFreeShow,
      applePayCashShow,
      setTokenPayInfo,
      handleTokenPay,
      normalTokenPayResultHandle,
      initTokenPayConfig,
      initPayJsonWebToken,
      sendDdc,
      setTokenPayCvv,
      visiblePrePay,
      formatPrePayCheckoutInfo,
      updatePrePaySetCheckoutInfo,
      prePayEventBus,
      ddcFormParams,
      initPrePayForCheckoutConfig,
      setPrePayCheckoutInfo,
      handlePayForPreCheckout,
      challengeModalOptions,
      prePayUnPayParams,
      prePayFailGuide,
      prePayLanguage,
      prePayCheckoutInfo,
      cardBinFreezeParams,
      prePayBinFreezeFailConfig,
      pageReadyTime,
      clickContinueResultInfo,
      prePayOrderInfo,
      handlePrePayForCheckout,
      prePayGetInstallmentInfo,
      prePayModalRef,
      prePayMergeState,
      prePayUpdateState,
      checkPrePayParamsChange,
      handleQueryUnPayPreRoutingInfo,
      changeCardBinDiscountInfoData,
      resetPrePayCheckoutInfo,
      reportClickContinueResult
      // virtualTokenInfo,
      // appendVirtualTokenInfo,
      // resetVirtualTokenInfo
    }
  },
  asyncData({ store, context }) {
    console.log('spa ssr')
    if (context && context.ssrPageType === 'checkout') {
      Object.assign(store.state['checkout'], stateFactory(), {
        checkout: context.checkout,
        checkoutAbtInfo: context.checkout.abtInfo || {},
        language: context.language,
        ssrPageType: context.ssrPageType,
        locals: context.locals,
        isSSR: true,
        prerenderSsr: context.prerenderSsr,
        togetherBuy: context.checkout.togetherBuy
      })
    } else if (typeof window != 'undefined' && window.gbCheckoutSsrData) {
      Object.assign(
        store.state['checkout']['bankSelect'],
        stateFactory('bankSelect')
      )
      Object.assign(store.state['checkout'], stateFactory(), {
        checkout: window.gbCheckoutSsrData.checkout,
        checkoutAbtInfo: window.gbCheckoutSsrData.checkout.abtInfo || {},
        language: window.gbCheckoutSsrData.language,
        ssrPageType: gbCheckoutSsrData.ssrPageType,
        isSSR: !!gbCheckoutSsrData.prerenderSsr,
        prerenderSsr: !!gbCheckoutSsrData.prerenderSsr,
        lowLevelSSR: true,
        togetherBuy: gbCheckoutSsrData.checkout.togetherBuy
      })

      Object.assign(store.state.checkout, {
        defaultSelectTokenPay: window.gbCheckoutSsrData.checkout.nowDefaultTokenByBin || {},
        handlerSelectBin: window.gbCheckoutSsrData.checkout.nowDefaultTokenByBin?.card_bin || '',
        installmentSelectBin: window.gbCheckoutSsrData.checkout.defaultTokenByMallCal?.card_bin || '', // 计价接口返回的最优 token bin
        installmentSelectToken: window.gbCheckoutSsrData.checkout.defaultTokenByMallCal || {}, // 计价接口返回的最优 token bin
      })
    }
  },
  data: function () {
    return {
      PUBLIC_CDN,
      lang,
      isFirstPageLoading: true,
      site: lang,
      SiteUID,
      loadingShow: false,
      orderUnpaid: false,
      googleValid: false, // 浏览器是否兼容google pay
      qiwiPayPhone: '', // qiwi phone
      qiwi: {
        phoneShow: false,
        phoneError: 0
      },
      // boleto邮箱检验
      emailTips: {
        val: '',
        show: false,
        err: ''
      },
      applePay: {
        show: false,
        orderInfo: {}
      },
      discountLimitIds: '',
      messageBox: {
        show: false,
        text: ''
      },
      walletVeriySuc: 0,
      showRiskDialog: false,
      isShowCouponTips: false,
      hasGuide: false,
      payInlineAbtInfo: {}, // klarna等分期直连支付方式abt
      payInlineSessionInfoCache: {}, // klarna等分期直连支付方式session缓存
      payInlineSessionInfo: {}, // klarna等分期直连支付方式session
      needPayInlinePaymentMethods: [], // klarna等分期直连支付方式
      giftCardLimit: {
        show: false,
        txt: '',
        verify: 0,
        clickConfirm: false
      },
      mixPrivacy: { show: false, locationId: '', needPop: false },
      billnoList: [],
      mixPrivacyAbt: { loginPrivac: '', placeOrder: '' },
      googlePay: {
        show: false
      },
      ssrAlreadyInit: false,
      flashLimitIds: '',
      visibleCardBinDiscountArticle: false,
      hackZIndex: null,
      interestsDataHasReport: false,
      isShowInstallmentTokenDrawer: true // 是否展示分期token弹框
    }
  },
  beforeRouteEnter(to, from, next) {
    if (typeof window == 'undefined') return next()
    sa('set', 'setPageData', {
      page_id: 11,
      page_name: 'page_checkout',
      page_param: {
        page_from: ''
      },
      start_time: new Date().getTime()
    })
    window.googlePayment = null
    next(vm => {
      vm.handleCheckoutData(from)
    })
  },
  beforeRouteLeave(to, from, next) {
    const windowHeight = window.innerHeight
    const currentScreen = Math.ceil(document.documentElement.scrollTop / windowHeight) + 1

    daEventCenter.triggerNotice({
      daId: '1-11-1-202',
      extraData: {
        page_depth: currentScreen
      }
    })

    this.changeParamsStatus({
      type: 'pageStatus',
      params: { giftCardDrawer: false, chooseCouponDrawer: false }
    })
    window.gbCheckoutSsrData = {}
    window.googlePayment = null
    window.shem_caculate_data = {}
    next()
  },
  computed: {
    ...mapGetters('checkout', [
      'storeAddressSite',
      'iconPrefix',
      'localLang',
      'isShowSupportInfo',
      'timePercentAbt',
      'shoppingBagPosition',
      'showXtra',
      'currencySupport',
      'isShopType',
      'orderInterceptByAbt',
      'forcedShippingByAbt',
      'isShopTransit',
      'switchQsFlagByAbt',
      'shipTimeDesList',
      'isFreePrice',
      'isSelectTokenPay',
      'defaultSelectTokenPay',
      'paymentLogoList',
      'isSuggested',
      'isNoAddressEnterCheckoutByAbt',
      'isNotAddressScene',
      'isSelectInstallmentTokenPay',
      'isShowbrTaxhint',
      'touchRuleInfo',
      'taxHint',
      'xtraAutoController',
      'isShowTotalFee',
      'isUsePrePayForCheckout',
      'isCheckCardinstallmentInfoStatus'
    ]),
    ...mapGetters('checkout/bankSelect', ['paymentBankInfo']),
    ...mapState('checkout', [
      'locals',
      'prerenderSsr',
      'checkoutAbtInfo',
      'language',
      'placeOrderRiskId',
      'checkout',
      'price',
      'storeData',
      'defaultShipping',
      'initializeDefaultShipping',
      'paymentList',
      'notSupportCodReason',
      'status',
      'mallShippingMethods',
      'mallInsuranceCheck',
      'coupon',
      'point',
      'wallet',
      'pageStatus',
      'giftcard',
      'now_currency',
      'GB_out_stock',
      'walletInfo',
      'addresslist',
      'addresslistgray',
      'selectAddress',
      'blackMask',
      'paytmUPI',
      'hasElseWallet',
      'paymentOption',
      'codDisplay',
      'noSelectPayment',
      'fullWalletNoCurr',
      'couponGift',
      'specialGoodsModalShow',
      'loading',
      'usableCouponList',
      'cartPromotionMap',
      'freezTipsAndGoods',
      'primeProductList',
      'usedPrimeIndex',
      'usedPrimeCode',
      'showMarkedPrice',
      'showShippingDayPercent',
      'shipPercentMethods',
      'dayPercentInx',
      'isClickedQsFreight',
      'qsFreightSelected',
      'isSSR',
      'asyncComponentLoadedStatus',
      'lowLevelSSR',
      'showAddrPage',
      'showShippingFeeDrawer',
      'bestCoupon',
      'autoCouponInfo',
      'bestCouponInfo',
      'shippingFeeRuleInfo',
      'loadingParam',
      'usedXtraCode',
      'xtraProductInfo',
      'shippingTypeChangeInfo',
      'shippingLimitInfo',
      'selectStoreAddressShippingInfo',
      'togetherBuy',
      'shippingTypeChangeInfo',
      'createOrderLoading',
      'mallCartsList',
      'defaultSelectTokenPay',
      'forceUpdateShippingMethod',
      'tokenCvv',
      'buyNowNo',
      'buyNowFromUrl',
      'selectedLastPayment',
      'integrityPromotionInfo',
      'shippingContentInfo',
      'createOrderBin',
      'createOrderBinDialogShow',
      'handlerSelectBin',
      'isNoNeedCardBin',
      'isShowHomogenizationDrawer',
      'isHasBeenNoAddressDrawerShow',
      'isAddressCommonDone',
      'isHasAddressInEnterCheckout',
      'collectShippingTimeText',
      'soldOutModalShow',
      'prevCalcuMallParams',
      'installmentSelectBin',
      'installmentSelectToken',
      'selectInstallment',
      'isShowCvvDrawer',
      'isShowPriceDetail',
      'prePayForCheckoutReady',
      'isMallOrderCreateFail',
      'isMAESTRO',
      'isUseNewCard'
    ]),
    ...mapState('checkout/bankSelect', ['bankDrawerConfig', 'banksConfig', 'capitecDialog']),
    isLastGoods() {
      const carts = filterPromitionGoods(this.checkout.results?.carts?.carts)
      return carts.length == 1
    },
    showTaxHint() {
      const isBrSite = !!~this.SiteUID?.indexOf('br')

      const { match_goods = [] } = this.touchRuleInfo || {}
      const touchRuleId = match_goods?.map(item => item.id) || []
      let cartInclude =  false
      const carts = this.checkout.results?.carts?.carts || []
      carts.forEach(cart => {
        if(touchRuleId.includes(cart.id)) cartInclude = true
      })

      return !this.IS_RW && this.isShowbrTaxhint && isBrSite && !!this.taxHint && cartInclude && !this.isLastGoods
    },
    // 立即购
    showTogetherBuy() {
      if (this.checkoutAbtInfo?.NewUserCasualBuy?.param?.NewUserCasualBuy == 'new') {
        return false
      }
      // 默认现有逻辑
      return !!this.buyNowNo ? this.checkout.CHECKOUT_SWITCH_BUY_TOGETHER_NOW == 'on' : true
    },
    togetherBuyLocation() {
      return !this.IS_RW && !!+this.togetherBuy?.togetherBuyType
        ? +this.togetherBuy?.OrderGoodsLocation
        : 0 || 0
    },
    paymentOrderDetails() {
      let formatDetail = this.getPaymentsOrderDetails
        ?.map(cur => `${cur.businessModel}:${cur.companyId}`)
        .join(',')
      if (this.usedXtraCode) {
        const xtraDetail = `${this.xtraProductInfo?.business_model}:null`
        formatDetail = formatDetail
          ? `${formatDetail},${xtraDetail}`
          : xtraDetail
      }
      if (this.usedPrimeCode) {
        const currentPrime = this.primeProductList[this.usedPrimeIndex]
        const primeDetail = `${currentPrime?.business_model}:null`
        formatDetail = formatDetail
          ? `${formatDetail},${primeDetail}`
          : primeDetail
      }
      return formatDetail
    },
    IS_RW() {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW
    },
    firstMallList() {
      return (
        (this.mallShippingMethods.mall_list &&
          this.mallShippingMethods.mall_list[0]) ||
        {}
      )
    },
    isClient() {
      return typeof window != 'undefined'
    },
    isChangePrimeSaverLocation() {
      return this.checkoutAbtInfo?.LocationPrimeSaver?.param?.LocationPrimeSaverStatus == '1'
    },
    //默认运输方式曝光埋点
    viewShippingAnalysis() {
      if (typeof window == 'undefined') return {}
      const defaultShippingList = (this.defaultShipping || []).map(item => {
        const default_shippingmethod =
          item?.shipping_method?.transport_type || ''
        let default_from = 0 //默认运输方式来源；0-后端返回 1-前端缓存
        if (this.checkout.cacheInfo?.mall_transport_type?.used) {
          const cacheTransportTypeList =
            this.checkout.cacheInfo.mall_transport_type.val
          if (
            cacheTransportTypeList.some(
              val =>
                val.mall_code == item.mall_code &&
                val.transport_type == default_shippingmethod
            )
          ) {
            default_from = 1
          }
        }
        return { default_shippingmethod, default_from }
      })
      if (defaultShippingList.length > 1) {
        return {
          default_from: defaultShippingList
            .map(item => item.default_from)
            .join('^'),
          default_shippingmethod: defaultShippingList
            .map(item => item.default_shippingmethod)
            .join('^')
        }
      }
      return defaultShippingList[0]
    },
    isChooseFoldedPayment() {
      const { folded_position, originFoldedPosition } =
        this.checkout.results || {}
      if (
        !shem_caculate_data.payment_id ||
        folded_position !== -1 ||
        originFoldedPosition <= 0
      ) {
        return false
      }
      const selectedPaymentIndex =
        this.checkout.results.paymentMethods.findIndex(
          item => item.id === shem_caculate_data.payment_id
        )
      return selectedPaymentIndex >= originFoldedPosition
    },
    cartsData() {
      const mallCode = this.firstMallList.mall_code || '1'
      return (
        this.checkout?.results?.carts?.carts?.filter(
          item => item.mall_code == mallCode
        ) || []
      )
    },
    quickShipInfo() {
      const { mallShippingMethods } = this
      const mallCode = this.firstMallList.mall_code || '1'
      const filterShip =
        mallShippingMethods?.mall_list?.find(
          item => item.mall_code == mallCode
        ) || {}
      return {
        desc_quick_ship_time: filterShip?.desc_quick_ship_time || '',
        quick_ship_time: filterShip?.quick_ship_time || ''
      }
    },
    cartsMallInfo() {
      const mallCode = this.firstMallList.mall_code || '1'
      return (
        this.checkout?.results?.carts?.mall_list?.find(
          item => item.mall_code == mallCode
        ) || {}
      )
    },
    riskDialogFocus() {
      return {
        show: this.showRiskDialog,
        first: () => {
          setTimeout(() => {
            this.$refs.riskTitle.focus()
          }, 500)
        }
      }
    },

    paymentMethodsProps() {
      return {
        googleValid: this.googleValid,
        language: this.language,
        checkout: this.checkout,
        paymentList: this.paymentList,
        codDisplay: this.codDisplay,
        notSupportCodReason: this.notSupportCodReason,
        price: this.price,
        status: this.status,
        paymentOption: this.paymentOption,
        banksConfig: this.banksConfig,
        noSelectPayment: this.noSelectPayment,
        clickPayment: this.clickPayment,
        handleStartSelectBank: this.handleStartSelectBank,
        showMaskTips: this.showMaskTips,
        needPayInlinePaymentMethods: this.needPayInlinePaymentMethods,
        showWidgetForm: this.showWidgetForm,
        ppgvAccountInfo: this.ppgvAccountInfo,
        afterPayAccountInfo: this.afterPayAccountInfo,
        paypalGaVault: this.paypalGaVault,
        afterPayVault: this.afterPayVault,
        iconPrefix: this.iconPrefix,
        locals: this.locals,
        showPaypalGaType: this.showPaypalGaType,
        confirmPPGVType: this.confirmPPGVType,
        confirmAfterPayType: this.confirmAfterPayType,
        unfold: this.unfold,
        handlePaymentOptionsOper: this.handlePaymentOptionsOper,
        resetBanksConfig: this.resetBanksConfig,
        klarna: this.klarna,
        nowPageType: this.nowPageType
      }
    },

    // 是否展示
    isFooterSelectPayment() {
      if (this.paymentMethodsProps?.nowPageType !== 1) return false
      const selectedLastPayment = this?.checkout?.selectedLastPayment

      const { orderpaylistbottomdefault = {}, orderpaylistbottomnodefault = {} } = this.checkout?.abtInfo || {}
      if (Object.keys(selectedLastPayment || {})?.length > 0 && orderpaylistbottomdefault?.param?.orderpaylistbottomstatus == 1) return true
      if (Object.keys(selectedLastPayment || {})?.length === 0 && orderpaylistbottomnodefault?.param?.orderpaylistbottomnodefaultstatus === 'bottom') return true
      return false
    },
    checkShowTotalFee () {
      return this.isShowTotalFee && 
      this.price?.pay_method_gov_tax_info?.pay_method_gov_tax_rate > 0 &&
      Number(this.price?.pay_method_gov_tax_info?.pay_method_gov_tax_amount?.amount || '0') > 0
    },
    govTaxPrice () {
      return this.price?.pay_method_gov_tax_info?.pay_method_gov_tax_amount?.amountWithSymbol || 0
    },
    // 判断是否展示ca gst 提示文案
    isShowCaGST () {
      const { countryId } = this.checkout && this.checkout.default_address || {}
      return (countryId == '38' || this.SiteUID == 'mca')
    },

    prePayPlaceOrderBtnText() {
      if (this.prePayUnPayParams?.installmentsInfo?.stage_num === 1) return this.language.SHEIN_KEY_PWA_21233 
      const priceDom = `<span class='pre-pay-price'>${this.prePayUnPayParams?.installmentsInfo?.installment_amount}</span>`
      return Object.keys(this.prePayUnPayParams?.installmentsInfo || {}).length 
        ? _commonTemplate(priceDom, this.language?.SHEIN_KEY_PWA_31967)
        : this.language.SHEIN_KEY_PWA_21233 
    },

    // 当出现bin优惠无论随机立减还是满减满折，需要用户double check，二次拉起卡分期弹窗
    isPrepayHadBinDiscount() {
      return this.prePayUnPayParams?.isHadBinDiscount
    }
  },
  watch: {
    'blackMask.showMask'(v) {
      if (v && !this.blackMask.isLoaded) {
        this.blackMask.isLoaded = true
        this.updateState({
          key: 'loading',
          value: true
        })
      }
    },
    orderUnpaid(v) {
      this.handleCheckoutDialogLoaded(v)
    },
    'giftCardLimit.show'(v) {
      this.handleCheckoutDialogLoaded(v)
    },
    'messageBox.show'(v) {
      this.handleCheckoutDialogLoaded(v)
    },
    'applePay.show'(v) {
      this.handleCheckoutDialogLoaded(v)
    },
    'qiwi.phoneShow'(v) {
      this.handleCheckoutDialogLoaded(v)
    },
    'emailTips.show'(v) {
      this.handleCheckoutDialogLoaded(v)
    },
    'mbwayInfo.dialogShow'(v) {
      this.handleCheckoutDialogLoaded(v)
    },
    isShowCouponTips(v) {
      this.handleCheckoutDialogLoaded(v)
    },
    'checkout.mall_caculate_info': function () {
      this.getInterestsData()
      // 当计价有变动，即出现切换了支付方式等场景时，需要判断是否需要清空卡分期前置缓存数据
      this.checkResetPrePayCheckoutInfo()
    },
    'showXtra': function(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.getInterestsData()
      }
    },
    showTogetherBuy() {
      this.getInterestsData()
    },

    paymentMethodsProps: {
      handler: function (newVal) {
        this.assignState({
          paymentMethodsProps: newVal
        })
      },
      deep: true
    },
    visiblePrePay: {
      handler(val) {
        this.assignState({
          visiblePrePay: val
        })
      }
    },
    prePayUnPayParams: {
      handler(val) {
        this.assignState({
          prePayUnPayParams: val
        })
      }
    }
  },
  beforeMount() {
    if (this.prerenderSsr) {
      this.$el = document.querySelector('#prerender-app #checkout-app')
    }
  },
  mounted() {
    CustomQuotaInstance.sendBatchData()
    this.pageType = 'checkout'
    this.bindWindowsEvents()
    this.exchangingSpaScene && this.exchangingSpaScene(false)
    this.$nextTick(() => {
      requestAnimationFrame(() => {
        const footerH = $('.j-checkout-footer').height()
        $('.c-checkout-page').css('padding-bottom', `${footerH}px`)

        this.asyncComponentLoadedStatus.addressCommonIsLoaded = true
      })
    })
    this.getRiskifiedDeviceid()
    import(
      /* webpackChunkName: "checkout-checkout-js" */ './components/checkout'
    )

    //同盾js
    // TR-10891【交易前台】商城下单/支付页接入同盾设备指纹ssrRenderTimeDiff
    import('public/src/pages/common/RiskCommon/libs/BlackBox/index.js').then(
      ({ loadTdLibJs }) => {
        loadTdLibJs()
      }
    )

    //将创建订单需要的fingerprint提前
    window._GB_DeviceFingerPrint.callFunc(() => {})

    this.fetchFunctionHandler()

    publishCartCheckout()
    console.log('ssr render to string time: ' + window.gbCheckoutSsrData.ssrRenderTimeDiff)
    // 监听回退事件
    window.addEventListener(
      'popstate',
      () => {
        if (!this.$router && !location.href.includes('/checkout')) {
          location.reload()
        }
      },
      false
    )
    this.handleCartsTspLabels()
    setTimeout(()=>{
      this.getInterestsData()
    })

    // 记录默认的运输方式
    this.assignState({ initializeDefaultShipping: this.viewShippingAnalysis })

    this.changeParamsStatus({ 
      type: 'asyncComponentLoadedStatus', 
      params: { 
        prePayCompsIsLoaded: true
      }
    })
    // token前置初始化
    // if (this.checkout?.memberPreCardTokenInfo?.isHaveInitTokenPay || this.checkout?.isShowTokenFrontInstallment) {
    this.changeParamsStatus({ 
      type: 'asyncComponentLoadedStatus', 
      params: { 
        tokenPayCompsIsLoaded: true
      }
    })
    this.initTokenPayConfig({ 
      checkoutType: 'normal',
      toggleLoadingStatus: status => { // 控制loading状态
        this.assignState({
          createOrderLoading: !!status
        })
      } 
    })
    // }

    this.initPrePayForCheckoutConfig({
      checkoutType: 'normal',
      toggleLoadingStatus: status => { // 控制loading状态
        this.assignState({
          createOrderLoading: !!status
        })
      } 
    })

    // address_common是异步加载的，并且通过v-if控制显隐，在mounted中无法通过ref或者id准确知道当前组件加载完成
    const unwatch = this.$watch('isAddressCommonDone', (val) => {
      if (val) {
        const noAddressInteractByAbt = this.checkoutAbtInfo?.NoAddressInteract?.param?.NoAddressInteract

        if (this.soldOutModalShow || !this.isNoAddressEnterCheckoutByAbt || !this.isNotAddressScene || this.isHasBeenNoAddressDrawerShow) {
          unwatch()
          return
        }

        noAddressInteractByAbt == 'typeB' && !!this.$refs.addressCom && setTimeout(() => { this.handleAddressAdd({ noHash: true }) }, 500)
        noAddressInteractByAbt == 'typeC' && this.assignState({ isShowNoAddressTipsDialog: true, isShowSelectShippingAddressTips: false })
        unwatch()
      }
    })

    if (this.isNotAddressScene) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-172',
        extraData: {
          scenes: 'no_address'
        }
      })
    }

    this.prePayEventBus?.updateCacuTotal?.on?.(({ data, noTips, cb }) => {
      console.log('prePayEventBus=====bin===', data)
      let prePayBinParams = {
        selected_bin: data?.selected_bin,
        bins: data?.bins,
        need_select: data?.need_select
      }
      this.mallCacuTotal({
        opt: {
          ...shem_caculate_data,
          ...(data?.selected_bin ? prePayBinParams : {})
        },
        loadingInfo: {
          show: noTips ? false : true
        },
        cb: res => {
          if (res.code != 0) {
            this.resetMallCacuTotal()
          }
          if (typeof cb === 'function') return cb(res)
          const prePayCheckoutInfo = this.formatPrePayCheckoutInfo({
            mallCaculateInfo: this.checkout.mall_caculate_info,
            defaultAddress: this.checkout.default_address,
            normalGoodsList: this.checkout?.results?.carts?.carts || [],
            usedPrimeProductInfo: this.primeProductList?.[this.usedPrimeIndex] || {},
            usedXtraProductInfo: this.usedXtraCode && this.xtraProductInfo || {},
            routeCode: this.status.cardPay,
            bin_discount_list: this.checkout?.results?.bin_discount_list || [],
            bin_discount_info: this.checkout.results?.bin_discount_info || {},
            default_address: this.checkout.default_address,
            isAutoRenewal: this.isAutoRenewal
          })
          this.updatePrePaySetCheckoutInfo(prePayCheckoutInfo)
          this.prePayGetInstallmentInfo()
          this.prePayUpdateState('displayCardBinDiscountInfo', this.prePayCheckoutInfo?.cardBinDiscountInfo || {})
          // 单后埋点信息
          const product_type = this.usedXtraCode ? 'order_sheinsaver' : this.usedPrimeCode ? 'order_membership' : 'commontype'
          this.prePayMergeState('clickContinueResultInfo', {
            prime_deduction: this.checkout?.mall_caculate_info?.prime_deduce_price?.amountWithSymbol || '',
            product_type
          })
          this.prePayEventBus?.cacuTotalFinish?.emit?.(res)
        }
      })
    })
    this.prePayEventBus?.createOrder?.on?.(({
      skipFreezeOperate = false,
      failReasonResults,
      prePayCardVaildFail = false
    } = {}) => {
      this.assignState({
        prePayForCheckoutReady: prePayCardVaildFail ? false : true
      })
      if (prePayCardVaildFail) {
        SIMetric.metricCount({
          metric_name: 'web_payment_error_total', // 指标名称
          tags: { // 指标维度
            sub_site: gbCommonInfo?.SiteUID || '',
            error_scene: this.prePayCheckoutInfo?.billno ? 'pre_un_pay_vaild_fail_scene' : 'pre_pay_vaild_fail_scene',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          },
          message: `err: ${failReasonResults?.join?.(',')}, billno: ${this.prePayCheckoutInfo?.billno}`
        })
      }
      if (this.prePayCheckoutInfo.billno) {
        const getIsCvvLimit = (cvv = '') => {
          const cvvValIsNoLimitList = ['000', '0000', '']
          const cvvErr = failReasonResults?.find?.(v => v?.startsWith?.('cvv'))
          return cvvErr || !cvvValIsNoLimitList.includes(cvv)
        }
        this?.prePayMergeState('clickContinueResultInfo', {
          status: 'fail',
          failure_reason: prePayCardVaildFail ? JSON.stringify({
            code: '-',
            result: failReasonResults?.join?.(',') || ''
          }) : {},
          failure_type: 'front_end',
          scene: 'new',
          instalment_num: this.prePayUnPayParams?.installments || '',
          is_remember: this.prePayUnPayParams?.rememberCard ? '1' : '0',
          stay_time: parseInt((Date.now() - this.pageReadyTime) / 1000),
          payment_method: this.prePayCheckoutInfo?.routeCode || '',
          // is_need_cvv: failReasonResults?.find?.(v => v?.startsWith?.('cvv')) ? '1' : '0',
          is_need_cvv: '-',
          is_cvvnolimit: getIsCvvLimit(this.prePayCheckoutInfo?.cvv) ? '0' : '1',
        })
        if (prePayCardVaildFail) {
          this.reportClickContinueResult(this.clickContinueResultInfo)
          return
        }
        this.assignState({
          createOrderLoading: true
        })
        this.handlePreUnPay(this.prePayOrderInfo, { skipFreezeOperate })
      } else {
        this.createOrder({ prePayCardVaildFail })
      }
    })
    window.addEventListener('offline', (e) => {
      this.assignState({
        isOfflineStatus: true
      })
    })

    window.addEventListener('online', (e) => {
      this.assignState({
        isOfflineStatus: false
      })
    })
  },
  updated: function () {
    this.$nextTick(function () {
      const footerH = $('.j-checkout-footer').height()
      $('.c-checkout-page').css('padding-bottom', `${footerH}px`)
    })
  },
  created() {
    const ssrPageType = this.$store.state['checkout'].ssrPageType || ''
    this.resetLocals()
    // ssr参数fix
    this.nowPageType = 1
    this.setNowCheckoutScene('Checkout')
    if (typeof window != 'undefined') {
      this.bindEvent()
    }
    if(ssrPageType === 'checkout') {
      if(!this.checkout.results) return
      this.initSsrData({ checkout: this.checkout, language: this.language })
      this.initCheckoutSSR()
      this.newUpdateCouponModule(this.checkout.couponListForOrderInfo, {
        isFirst: true
      })
    }

    if (!this.$router) {
      typeof window != 'undefined' && this.handleCheckoutData()
    }
  },
  destroyed() {
    if (typeof window != 'undefined') {
      window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
      document.removeEventListener('click', () => {})
    }
  },
  methods: {
    ...mapMutations('checkout', [
      'assignState',
      'changeParamsStatus',
      'clickStoreAddress',
      'showMaskTips',
      'updatePaymentMethods',
      'updatePaymentLogoInfo',
      'updateCheckoutGuideLogoList',
      'resetLocals',
      'updateState',
      'showPageLoading',
      'hidePageLoading',
      'changeTogetherBuy',
      'changeVirtualCouponInfo',
      'updateShippingAddStatusInfo',
      'updateCreateOrderBinDialogShow',
      'updateIsNoNeedCardBin',
      'changeHomogenizationDrawerVisible'
    ]),
    ...mapActions('checkout', [
      'initCheckoutSSR',
      'payLimit',
      'handleNotSupportCodTip',
      'updateStock',
      'mallCacuTotal',
      'clickPayment',
      'updateCart',
      'getCartPromotionInfo',
      'handleCod',
      'changeXtraUpdateCalcu',
      'updateXtraPaymentsRight',
      'handleShipInterceptModal',
      'handleCartsTspLabels',
      'resetMallCacuTotal',
      'getShippingFreeMall',
      'handleSelectTokenPay'
    ]),
    ...mapMutations(['exchangingSpaScene']),
    ...mapMutations('checkout/bankSelect', ['updateBanksConfig', 'updateCapitecDialog', 'updateBankDrawerConfig']),
    ...mapActions('checkout/bankSelect', [
      'handleBankSelect',
      'handleStartSelectBank',
      'handleCapitecDialogEvent',
      'resetBanksConfig'
    ]),
    resetShippingData(shippingList) {
      const insuranceMallData = shippingList?.insuranceMallData || {}
      const mallInsuranceCheck = updateMallInsuranceCheck({
        insuranceMallData,
        forcedShippingByAbt: this.forcedShippingByAbt,
        preMallInsuranceCheck: this.mallInsuranceCheck
      })

      let mallShippingMethods = shippingList?.mallShippingList?.info || {}
      this.checkout.results.mallShippingMethods = mallShippingMethods
      this.checkout.results.insuranceMallData = insuranceMallData || {}
      this.assignState({
        mallInsuranceCheck: mallInsuranceCheck,
        mallShippingMethods: mallShippingMethods
      })
    },
    getPaymentList(params) {
      return new Promise(resolve => {
        schttp({
          url: '/api/checkout/paymentList/get',
          params
        })
          .then(res => {
            resolve(res)
          })
          .catch(() => {
            resolve({})
          })
      })
    },
    getShippingList(params) {
      return new Promise(resolve => {
        schttp({
          url: '/api/checkout/shippingList/get',
          params
        })
          .then(json => {
            resolve(json)
          })
          .catch(() => {
            resolve({})
          })
      })
    },
    closeShippingFee() {
      this.assignState({
        showShippingFeeDrawer: false,
      })
    },
    handleCheckoutData(from = {}) {
      window.PageGroup = '下单页'
      window.PageGroupOverview = '购物流程'
      let page_from = ''
      if( document.referrer ){
        if( document.referrer.indexOf('/user/login') > -1 ){
          page_from = 'login'
        }
        if (document.referrer.indexOf('/cart') > -1) {
          page_from = 'page_cart'
        }
      }
      if (from.path?.indexOf('/cart') > -1) {
        page_from = 'page_cart'
      }
      const { page_from: pageName } = parseQueryString(window.location.search)
      if(!!pageName) {
        page_from = pageName
      }

      // 路由进入手动刷新数据
      SaPageInfo = {
        page_id: 11,
        page_name: 'page_checkout',
        page_param: {
          page_from,
          is_combine: getLocalStorage('is_combine') == '0' ? 0 : 1
        },
        start_time: new Date().getTime()
      }

      let posKeys = []
      let { paymentMethods, carts, mallShippingMethods, primeProductInfo } =
        this.checkout.results || {}
      const PaymentABT = this.checkoutAbtInfo['PaymentABT'] || {}
      if (PaymentABT && paymentMethods?.length) {
        const paymentOffAbtValue =
          PaymentABT?.p?.indexOf?.('paymentoff=') > -1
            ? PaymentABT.p.split('=')[1]
            : ''
        if (paymentOffAbtValue) {
          posKeys.push('SMPaymentABT')
        }
      }
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtestVal = abtservice.getUserAbtResultForAnalysis({
        posKeys: posKeys.join(','),
        abtInfo: this.checkoutAbtInfo
      }).sa
      if (SaPageInfo.page_param.abtest) {
        SaPageInfo.page_param.abtest += ',' + abtestVal
      } else {
        SaPageInfo.page_param.abtest = abtestVal
      }
      SaPageInfo.page_param.is_shop = this.isShopType

      if (mallShippingMethods?.mall_list?.length) {
        let mallShippingMethodsMallCode = mallShippingMethods.mall_list.map(
          item => {
            return item.mall_code
          }
        )
        SaPageInfo.page_param.mall_code = mallShippingMethodsMallCode.join(',')
      }
      SaPageInfo.page_param.buy_tp = primeProductInfo?.primeProductList.length
        ? '2'
        : '0'

      let activity_method = judgeActivityIsExist(carts?.carts || [], [3, 10])
      SaPageInfo.page_param.activity_method = activity_method

      this.$nextTick(() => {
        this.initData({ checkout: this.checkout, language: this.language })
        requestAnimationFrame(() => {
          this.initEvent()
        })
      })
    },
    bindWindowsEvents() {
      window.GB_CHECKOUT_VUE = this
      window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
      document.addEventListener('ftr:tokenReady', function (evt) {
        window.forterDeviceId = evt.detail || window.forterDeviceId || ''
      })
      document.addEventListener('click', () => {
        if (this.autoCouponInfo.show) {
          this.changeParamsStatus({
            type: 'autoCouponInfo',
            params: {
              show: false
            }
          })
        }
        if (this.bestCouponInfo.show) {
          this.changeParamsStatus({
            type: 'bestCouponInfo',
            params: {
              show: false
            }
          })
        }
      })
      this.initCybersource()
      this.forterLoad()
    },
    closeLoading() {
      this.updateState({
        key: 'loading',
        value: false
      })
    },
    closePercentList() {
      this.assignState({
        showShippingDayPercent: false,
        shipPercentMethods: [],
        dayPercentInx: 0
      })
    },
    exposeAddressFrom(extraData = {}) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-74',
        extraData: {
          ...extraData,
          address_type: this.addresslist?.length ? 1 : 0
        }
      })
    },
    /**
     *
     * 1、支付方式列表返回有默认支付方式*且*计算价格接口返回下单币种钱包余额为0但有其他币种钱包余额时
     * 2、支付方式列表返回默认支付方式为cod*且*除去COD服务费以外，客户此单应付金额为0时
     *
     */
    initDefaultMethods() {
      this.checkout.paymentSuggestion = ''
      this.changeParamsStatus({ type: 'status', params: { cardPay: '' } })
      shem_caculate_data.payment_id = ''
      shem_caculate_data.payment_code_unique = ''
      let paymentLists = this.checkout?.results?.paymentMethods || []
      // 没有选择默认支付方式 支付方式按照后端顺序排序
      paymentLists = paymentLists.sort((prev, next) => {
        if (prev.sort > next.sort) {
          return 1
        } else if (prev.sort < next.sort) {
          return -1
        } else {
          return 0
        }
      })
      this.updatePaymentMethods({ list: paymentLists })
    },
    resetDefaultMethods({ action = null } = {}) {
      const paymentLists = this.checkout?.results?.paymentMethods || []

      // URL上的当前支付方式编码
      let urlPaymentCode = ''
      if (this.isClient) {
        urlPaymentCode = getQueryString({ key: 'paymentCode' }) || ''
      }

      const lastPayment = paymentLists.find( item => {
        if (!this.IS_RW && urlPaymentCode) {
          return item.code === urlPaymentCode && item.enabled == 1
        }
        return item.last_payment && item.last_payment == 1 && item.is_display == 1 && item.enabled == 1
      }) || {}

      const noUseLastPayment = this.checkout?.results?.noUseLastPayment || []
      const isNoSort = noUseLastPayment.includes(
        lastPayment?.code?.toUpperCase()
      )
      if (isNoSort) {
        this.initDefaultMethods()
        return
      }

      const { isChangeCurrency } = handleSwitchCurrency({
        currencySupport: this.currencySupport,
        currency: this.checkout.mall_caculate_info.orderCurrency.code,
        countryCode: this.checkout.default_address.value,
        paymentCode: lastPayment.code
      })
      if (isChangeCurrency) {
        this.initDefaultMethods()
      } else if (
        lastPayment.code?.toLowerCase() === 'cod' &&
        +this.price?.grandTotalPrice?.amount === 0
      ) {
        this.initDefaultMethods()
      } else {
        const totalPrice = this.price?.wallet?.totalPrice || []
        const totalAmount = totalPrice.reduce((prev, next) => {
          return prev + Number(next?.price?.amount || 0)
        }, 0)
        if (totalAmount !== 0) {
          this.initDefaultMethods()
        } else {
          action && action()
        }
      }
    },
    loginModalSucCb({ scene } = {}) {
      // 查看钱包风控验证完成后
      if (scene == 'view_wallet_balance') {
        this.walletVeriySuc = 1

        this.mallCacuTotal({
          opt: shem_caculate_data,
          cb: () => {
            this.$refs.couponOptions.updateRiskStr()
          }
        })

        // 下单失败风控验证完成后
      } else if (scene == 'choose_wallet') {
        this.createOrder()
      }
    },
    riskConfirmCb() {
      this.closeRiskDialog()
    },
    closeRiskDialog() {
      this.showRiskDialog = false
      if (
        this.$refs.mainLogin.scene == 'view_wallet_balance' &&
        this.walletVeriySuc == 1
      ) {
        location.reload()
      }
    },
    beforeunloadFn() {
      this.loadingShow = false
    },
    // 初始化支付方式对应银行列表
    initBankLists(paymentMethods = []) {
      paymentMethods.forEach(payment => {
        if (payment.bank_list && payment.bank_list.length) {
          const banks = payment.bank_list.map(item => {
            const { code = '', name = '' } = item || {}
            return {
              ...item,
              id: code,
              value: name
            }
          })
          const backupConfig = this.banksConfig[payment.code] || {}
          const matchSelect =
            banks.find(item => item.code == backupConfig.bankCode) || {}
          this.updateBanksConfig({
            [payment.code]: {
              list: banks,
              bankCode: matchSelect?.code || -1,
              bankCodeText: matchSelect.name || '',
              bankLogo: matchSelect?.logo || '',
              error: false
            }
          })
        }
      })
    },
    initData(opt) {
      // 需要进入每次进入路由即要初始化的数据
      if (opt && opt.checkout) {
        if (!this.ssrAlreadyInit) {
          this.initFirstPageData({
            checkout: opt.checkout,
            language: opt.language
          })
        }

        //地址滑动层，以防ada聚焦设置是否show
        var hashValue = location.hash
        hashValue = hashValue.replace('#', '')
        let showAddrPage = [
          'addressList',
          'addressEdit',
          'addressAdd'
        ].includes(hashValue)

        this.updateState({
          key: 'showAddrPage',
          value: showAddrPage
        })

        if (opt.checkout?.address_store_list?.length) {
          let address_store_list = opt.checkout?.address_store_list?.[0] || ''
          const countryId = this.checkout.default_address.countryId
          if (
            countryId == 209 &&
            (!address_store_list || address_store_list.status != '1')
          ) {
            this.assignState({ storeData: '' })
          } else {
            this.assignState({ storeData: address_store_list })
          }
        }

        // 店配地址
        if (
          TRANSPORT_TYPE_SHOP.includes(
            this.defaultShipping[0]?.shipping_method?.transport_type
          ) &&
          !this.storeData
        ) {
          this.getStoreAddress()
        }

        this.getCartPromotionInfo()

        let paymentMethods = opt?.checkout?.results?.paymentMethods || []
        this.initBankLists(paymentMethods)
        this.payInlineAbtInfo = opt.checkout?.results?.payInlineAbtInfo || {}
        this.getInlineAbt()
        window.klarnaAsyncCallback = this.klarnaAsyncCallback
        if (Object.keys(this.payInlineAbtInfo).includes('klarna')) {
          let hasKlarnaInline = paymentMethods.some(item =>
            this.payInlineAbtInfo['klarna'].includes(item.code)
          )
          hasKlarnaInline && this.initKlarnaPayInlineSDK()
        }

        this.initPayInlineSession({
          paymentMethods: paymentMethods,
          justReq: !this.ssrAlreadyInit
        })

        gbCommonInfo.csrf_token =
          opt.checkout.gb_csrf_token || gbCommonInfo.csrf_token


        // 价格计算接口字段
        window.shem_caculate_data = {
          address_id: this.checkout.default_address.addressId,
          mall_params: [],
          payment_id: '',
          coupon: '',
          use_wallet: 0,
          use_wallet_amount: '',
          comment: '',
          prime_product_code: '',
          save_card_product_code: '',
          ...shem_caculate_data,
        }

        this.updateCalcuMallParam({
          selectMallShip: this.defaultShipping,
          mallInsuranceCheck: this.mallInsuranceCheck,
          isInit: true
        })

        // 一站多合规
        this.getMixPrivacyAbt()
      }
    },
    // 初始化首屏数据
    initFirstPageData({ checkout, language }) {
      // 初始化运输方式信息
      let mallShipIndex =
        initMallShipIndex({
          mallShippingMethods: checkout.results?.mallShippingMethods,
          has_shop_transit_address: !!checkout.address_store_list?.length
            ? 1
            : 0,
          orderInterceptByAbt: this.orderInterceptByAbt,
        }) || []
      let selectMallShip = handleMallShippingInfo(
        checkout.results?.mallShippingMethods,
        mallShipIndex
      )
      let mallInsuranceCheck = updateMallInsuranceCheck({
        insuranceMallData: checkout.results?.insuranceMallData || {},
        forcedShippingByAbt: this.forcedShippingByAbt
      })
      // 应用shipping缓存
      if (checkout.cacheInfo?.mall_transport_type?.used) {
        selectMallShip = useTransportCache({
          checkout: checkout,
          mallShipIndex
        })
      }

      const sourceGrayTmp = this.filteGrayAddress(checkout.addressListGray)
      if (!!checkout.address_store_list?.length) {
        let address_store_list = checkout.address_store_list?.[0] || ''
        const countryId = checkout.default_address?.countryId
        if (
          countryId == 209 &&
          (!address_store_list || address_store_list.status != '1')
        ) {
          this.assignState({ storeData: '' })
        } else {
          this.assignState({ storeData: address_store_list })
        }
      }

      const newCarts = checkout.results?.carts?.carts || []
      newCarts?.forEach(item => {
        item.promotionTag = {}
        item.promotionTypeIds = []
        item.flashType = ''
      })

      this.assignState({
        checkout,
        language,
        price: checkout.mall_caculate_info || {},
        defaultShipping: selectMallShip,
        mallShippingMethods: checkout.results.mallShippingMethods,
        mallInsuranceCheck,
        addresslist: checkout.address_list,
        addressListGray: checkout.addressListGray,
        addresslistgray: sourceGrayTmp,
        selectAddress: checkout.default_address,
        primeProductList: checkout.results?.primeProductInfo?.primeProductList || [],
        lowPrimeProduct:
          JSON.parse(
            JSON.stringify(
              checkout.results?.primeProductInfo?.primeProductList?.filter(
                item => +item.product_price_info?.special_price > 0
              ) || []
            )
          ).sort(
            (a, b) =>
              +a.product_price_info?.special_price -
              +b.product_price_info?.special_price
          )[0] || {},
        codDisplay: +(
          checkout.mall_caculate_info.is_display ||
          checkout.mall_caculate_info.isDisabledCodPayment
        ),
        isPrimeMember: checkout.isPrimeMember || false,
        // 聚合超省卡信息，需要根据对应的code码拿对应的数据
        xtraAllProductBagInfo:
          checkout.results?.xtraAllProductBagInfo || {},
        xtraCouponList:
          checkout.results?.xtraCouponList?.couponList || [],
        xtraAggregatedCouponList:
          checkout.results?.xtraCouponList?.aggregatedCouponList || [],
        xtraProductBagInfo:
          checkout.results?.xtraProductInfo?.xtraProductBagInfo || [],
        xtraProductInfo:
          checkout.results?.xtraProductInfo?.xtraProductData || {},
        xtraDiscountInfo:
          checkout.results?.xtraDiscountInfo?.payment_right || {},
        xtraDisplayCouponInfo:
          checkout.results?.xtraDisplayCouponInfo?.data || {},
        primeSavingSummary:
          checkout.results?.primeSavingSummary?.savingSummary || {},
        autoPayList:
          checkout.results?.primeProductInfo
            ?.auto_renewal_limit_pay_type_list || []
      })

      this.initPaypalSignAbt({
        carts: checkout?.results?.carts?.carts || [],
        paymentMethods: checkout?.results?.paymentMethods || [],
        PayPalVaultingAbtInfo: checkout?.abtInfo?.PayPalVaultingnew?.p || '',
        Platformitemsvaultingoption:
          checkout?.abtInfo?.Platformitemsvaultingoption?.p || ''
      })

      this.initAfterpaySignAbt(checkout?.abtInfo)
    },
    initSsrData(opt) {
      this.ssrAlreadyInit = true
      this.initFirstPageData({ checkout: opt.checkout, language: opt.language })
    },
    initEvent() {
      // 需要进入每次进入路由即要处理的事件
      const _this = this

      if (
        this.checkout.results &&
        this.checkout.results.paymentMethods &&
        this.checkout.results.paymentMethods.length
      ) {
        this.updatePaymentMethods({
          originFoldedPosition: this.checkout?.results?.folded_position
        })

        // const hasPaypal = this.checkout.results.paymentMethods.some(item =>
        //   item.code === 'PayPal-GApaypal'
        // )
        // const foldPayment = getQueryString({ key: 'foldPayment' }) == '1'
        // if (this.isClient && foldPayment && hasPaypal) {
        //   // 需要折叠支付方式
        //   this.updatePaymentMethods({ folded_position: 1 })
        // }

        // 初始化辅助决策信息展示逻辑
        this.initSupportInfo()

        let hasGooglePay = this.checkout.results.paymentMethods.some(item =>
          googlePayMethods.includes(item.code)
        )

        if (hasGooglePay) {
          this.googlePayInit().then(() => {
            // 谷歌pay初始化
            this.initGooglePayment()
          })
        }
      }

      // applepay初始化-检测浏览器是否支持applepay
      this.initApplePay(_this.checkout.results.paymentMethods)

      // 当险种为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
      this.handleUseInsurance({
        mallInsuranceCheck: this.mallInsuranceCheck,
        insuranceMallData: this.checkout?.results?.insuranceMallData || {},
        updataCalcu: true
      })
      // 携带运输方式信息 发送pv
      this.getShipppingFree()

      // 默认支付
      this.getPaymentSuggestion()
      this.handleNotSupportCodTip(_this.checkout.mall_caculate_info)

      this.payLimit()
      this.updateStock(this.checkout.mall_caculate_info.outStockCarts)


      if (this.isFirstPageLoading) {
        // 依赖初始数据只需处理一次的事件
        analysisCheckoutEvent.bindEvent(_this.checkout)
        this.isFirstPageLoading = false
      }

      this.paypalSignAnalysis()

      this.initShippingAddStatus()
    },
    initShippingAddStatus() {
      if (window?.sessionStorage?.getItem) {
        const showStatus = sessionStorage.getItem('checkout_shipping_additem_success')
  
        this.updateShippingAddStatusInfo({
          getStatus: true,
          addStatus: showStatus == 1
        })
      }
    },
    bindEvent() {
      const _this = this

      //避免浏览器回退再进入下单页时重新订阅事件，先注销
      const cancelEventArr = [
        'payinline-load-widget',
        'payinline-reload-widget',
        'analysis-send',
        'update-coupon',
        'update-shipping-insurance',
        'update-shipping-method',
        'add-store-address',
        'update-store-address-complete',
        'update-store-address-cancel',
        'edit-store-address',
      ]
      cancelEventArr.forEach(name => {
        appEventCenter.$off(name)
      })

      appEventCenter.$on(
        'update-shipping-method',
        async ({
          address,
          shippingMethod = {},
          mallCode = '',
          isLimit = 0,
          updatePayment = 0,
          skipShipIntercept,
          modifyCart = false,
          isInterceptBack = false,
        }) => {
          // 即将选中的是否是店配
          const isStoreAddress = TRANSPORT_TYPE_SHOP.includes(shippingMethod?.transport_type)
          let shippingParams = {
            countryId: isStoreAddress ? this.storeData.countryId : address.countryId,
            address_id: isStoreAddress ? '' : address.addressId,
            has_shop_transit_address: !!_this.storeData ? 1 : 0,
            checkout_no: this.buyNowNo,
            state: address.state, // 州省
          }
          let arr = [this.getShippingList(shippingParams)]
          if (updatePayment == 1) {
            let paymentParams = {
              country: address.value || '',
              type: [
                ...new Set(
                  shem_caculate_data.mall_params?.map(
                    item => item.transport_type
                  )
                )
              ].join(),
              membership_order_scene: !!_this.primeProductList.length ? 2 : '',
              order_details: this.paymentOrderDetails,
              currency: address.newDefaultCurrency || '',
              xtra_order_scene: _this.showXtra ? 2 : ''
            }
            arr.push(this.getPaymentList(paymentParams))
          } else {
            arr.push(Promise.resolve({}))
          }
          const [shippingList, paymentList] = await Promise.all(arr)

          this.selectShipMethod(shem_caculate_data.address_id)
          this.resetShippingData(shippingList)

          if (updatePayment == 1) {
            this.resetPaymentInfo({
              opt: address,
              res: paymentList,
              cb: paymentList => {
                // 用户是否已选择支付方式
                const haveChosen = paymentList.info.payments.some(
                  item =>
                    shem_caculate_data.payment_code_unique == item.code &&
                    item.is_display == 1 &&
                    item.enabled == 1
                )

                // 勾选默认支付方式
                if (!haveChosen) {
                  // URL上的当前支付方式编码
                  let urlPaymentCode = ''
                  if (this.isClient) {
                    urlPaymentCode = getQueryString({ key: 'paymentCode' }) || ''
                  }
                  const lastPayment = paymentList.info.payments.filter(item => {
                    // 进入下单页面，需要根据购物车选择的bnpl支付方式选择默认的支付方式
                    if (!this.IS_RW && urlPaymentCode) {
                      return item.code === urlPaymentCode && item.enabled == 1
                    }
                    return item.last_payment && item.last_payment == 1 && item.is_display == 1 && item.enabled == 1
                  })
                    
                  
                  if (lastPayment && lastPayment[0]) {
                    const item = lastPayment[0]

                    this.resetDefaultMethods({
                      action: () => {
                        if (item.code == 'cod' && this.notSupportCodReason)
                          return

                        shem_caculate_data.payment_id = item.id
                        shem_caculate_data.payment_code_unique = item.code
                      }
                    })
                  } else {
                    this.checkout.paymentSuggestion = ''
                    this.changeParamsStatus({
                      type: 'status',
                      params: { cardPay: '' }
                    })
                    shem_caculate_data.payment_id = ''
                    shem_caculate_data.payment_code_unique = ''
                  }
                }
              }
            })
          }

          if (isLimit == 1) {
            this.handleShippingLimit({
              mallCode,
              shippingMethod,
              skipShipIntercept,
              modifyCart,
              isInterceptBack,
            })
          } else {
            this.handleShippingSelect({ scence: 1, modifyCart })
          }
        }
      )

      appEventCenter.$on('payinline-load-widget', () => {
        // fix 解决已经展示了paypal按钮，再次选中按钮状态变化的问题
        if (this.chosenChannel === 'paypal' && this.paypalBtnShow) return
        _this.showWidgetForm = 1
      })

      appEventCenter.$on('payinline-reload-widget', isClickPayment => {
        _this.initPayInlineSession({
          paymentMethods: _this.checkout?.results?.paymentMethods || [],
          cb: () => {
            let payInlineChannel = isClickPayment
              ? ''
              : _this.judgeNeedPayInlineChannel(
                shem_caculate_data.payment_code_unique
              )
            if (payInlineChannel) {
              _this[`${payInlineChannel}LoadWidget`] &&
                _this[`${payInlineChannel}LoadWidget`]()
            }
          }
        })
      })

      appEventCenter.$on('analysis-send', info => {
        const { paymentCode, checkout, item, idx, onlinePayDiscountInfoValue } = info
        if(!checkout) {
          return
        }
        const { folded_position, originFoldedPosition } = checkout?.results || {}
        daEventCenter.triggerNotice({
          daId: '1-11-1-130',
          extraData: {
            payment_method: paymentCode,
            is_vaulting:
              paymentCode == 'PayPal-GApaypal'
                ? !!this.ppgvAccountInfo?.signUpEmail
                  ? '1'
                  : '0'
                : '-',
            is_folded: (folded_position == -1 && idx >= originFoldedPosition) ? 1 : 0,
            is_pay_promotion: onlinePayDiscountInfoValue ? 1 : 0,
            is_default: (item?.enabled == 1 && item?.last_payment) ? 1 : 0,
            default_type: item?.last_payment ? 1 : 0,
            sequence: idx + 1
          }
        })
      })

      appEventCenter.$on('update-coupon', (opts = {}) => {
        let {
          opt = {},
          cb = null,
          notTips,
          onlyCoupon,
          noLoading,
          isPrime,
          isAuto,
          loadingInfo = {},
          res = {},
          isXtra,
          type = '',
          isModifyCart
        } = opts

        if (onlyCoupon) {
          this.updateCouponListOnly(opts)
        } else {
          if (!isAuto) {
            const { code, tips } = res
            if (code) {
              shem_caculate_data.coupon = ''
              this.$toast(
                couponCodeTips({
                  language: _this.language,
                  res: res,
                  text: tips
                })
              )
            }
          }

          const options = isAuto ? { notTips, ...opt } : opt
          this.getUpdateCoupon(options, {
            cb,
            isPrime,
            isXtra,
            isModifyCart,
            loadingInfo,
            noLoading:
              noLoading || (isAuto ? isPrime || loadingInfo.show : true),
            notTips,
            type:
              type ||
              (isAuto ? (isPrime || isXtra ? '' : 'originCallBack') : '')
          })
        }
      })
      appEventCenter.$on('update-shipping-insurance', () => {
        this.updateShippingInsurance({
          countryId: _this.checkout.default_address.countryId,
          address_id: _this.checkout.default_address.addressId,
          mallList:
            _this.mallShippingMethods?.mall_list?.map(item => {
              return { mall_code: item.mall_code }
            }) || []
        })
      })
      // request_card_token
      appEventCenter.$on(
        'checkout-update-payment',
        ({ 
          currency = '', 
          updatePaypalGaVaultStatus,  
          token_id = '', 
          isNeedMallCal = false,
          isInstallment = false,
          updateCb = () => {}, 
        } = {}) => {
          const countryValue =
            this.checkout?.default_address?.value?.toUpperCase() || ''

          let parames = {
            value: countryValue,
            newDefaultCurrency: currency,
            updatePaypalGaVaultStatus,
            request_card_token: 1
          }

          if (token_id && token_id !== '') {
            parames.token_id = token_id
            
            if (isInstallment) {
              parames.installment_token_id = token_id
            }
          }

          let cb = (data) => {
            updateCb && updateCb(data)
            isNeedMallCal && this.mallCacuTotal({
              opt: shem_caculate_data
            })
          }

          this.updatePayment(parames, cb)
        }
      )

      // 触发新增店配地址表单
      appEventCenter.$on('add-store-address', (params = {}) => {
        this.getStoreAddress(params)
      })

      appEventCenter.$on('update-store-address-complete', () => {
        // this.shippingLimitInfo?.checkUpdateShippingMethod - 存在下单限制逻辑时，店配地址更新后，更新运输方式后续逻辑
        // isAddStoreAddress - 选择店配运输方式时无店配地址，弹出新增店配地址后，需跳过下单限制逻辑走后续选中逻辑
        const {
          isAdd,
          mallCode = '',
          shippingMethod = {}
        } = this.selectStoreAddressShippingInfo || {}
        const isAddStoreAddress = !!isAdd
        if (
          this.shippingLimitInfo?.checkUpdateShippingMethod ||
          isAddStoreAddress
        ) {
          appEventCenter.$emit('update-shipping-method', {
            address: this.checkout?.default_address || {},
            shippingMethod: isAddStoreAddress
              ? shippingMethod
              : this.shippingLimitInfo?.shippingMethod || {},
            mallCode: isAddStoreAddress
              ? mallCode
              : this.shippingLimitInfo?.mallCode || '',
            skipShipIntercept: !!isAddStoreAddress,
            isLimit: 1
          })

          // 成功修改店配地址后，关掉地址限制弹窗
          this.assignState({
            showShippingLimitStore: false,
          })
        }
      })

      appEventCenter.$on('update-store-address-cancel', () => {
        if (this.selectStoreAddressShippingInfo?.isAdd) {
          this.handleShippingLimit({
            shippingMethod: this.selectStoreAddressShippingInfo?.shippingMethod,
            mallCode: this.selectStoreAddressShippingInfo?.mallCode,
            skipShipIntercept: true
          })
        }
      })

      appEventCenter.$on('again-choose-payment', (opts) => {
        console.log('again-choose-payment', opts)
        this.clickPayment(opts)
      })

      // 编辑店配地址
      appEventCenter.$on('edit-store-address', (params = {}) => {
        const { type, params: opts } = params
        this.emitStoreAddress(type, opts)
      })
    },
    handleShippingLimit({
      mallCode,
      shippingMethod,
      skipShipIntercept,
      modifyCart,
      isInterceptBack,
    }) {
      this.assignState({
        showShippingLimitAddress: false,
        showShippingLimitGoods: false,
        showShippingLimitStore: false,
      })

      // 此处判断是否受限应该要用即将真实使用的，可能存在请求接口之后原数据不存在场景
      const {
        shippingMethod: currShippingMethod,
        notIncludeMall,
        prevShippingMethod
      } = handleNextShippingMethod({
        mallShippingMethods: this.mallShippingMethods,
        mallCode,
        transportType: shippingMethod.transport_type,
        prevMallParams: shem_caculate_data.mall_params,
        orderInterceptByAbt: this.orderInterceptByAbt,
      })


      // 直接没有对应mall不弹限制弹窗；没有对应type继续受限需要弹限制弹窗
      if (notIncludeMall) {
        this.changeParamsStatus({
          type: 'shippingLimitInfo',
          params: { shippingLimitModalCount: 0 }
        })
        this.handleShippingSelect({ scence: 1, modifyCart })
        return
      }

      const { isIntercept, hintType } = isShipOrderIntercept({
        mallShippingMethods: this.mallShippingMethods,
        shippingMethod: currShippingMethod,
        mallCode,
        orderInterceptByAbt: this.orderInterceptByAbt,
      })

      const scence = skipShipIntercept && !isIntercept ? 1 : 3

      if (isIntercept && !skipShipIntercept) {
        this.handleShipInterceptModal({
          hintType,
          shippingMethod: currShippingMethod,
          mallCode
        })
      } else {
        this.changeParamsStatus({
          type: 'shippingLimitInfo',
          params: { shippingLimitModalCount: 0 }
        })

        // 是否是 即将切换的受限就切回当前选中的且跳过受限弹窗 场景
        if(isInterceptBack && isIntercept) {
          const prevTransportType = shem_caculate_data?.mall_params?.find(_ => _.mall_code == mallCode)?.transport_type
          const { shippingMethod: currShippingMethod } = handleNextShippingMethod({ 
            mallShippingMethods: this.mallShippingMethods, 
            mallCode, 
            transportType: prevTransportType, 
            prevMallParams: shem_caculate_data.mall_params, 
            orderInterceptByAbt: this.orderInterceptByAbt,
          })

          this.assignState({
            forceUpdateShippingMethod: {
              shippingMethod: currShippingMethod,
              mallCode: mallCode,
              isUpdate: true
            }
          })

          this.handleShippingSelect({
            scence,
            mallCode,
            transport_type: currShippingMethod.transport_type,
            item: currShippingMethod,
            modifyCart
          })

          return
        }

        // 店配宅配互斥弹窗
        const isShowMutexDialog = isShowMultiMallAddressMutex({
          mallShippingMethods: this.mallShippingMethods,
          isShopTransit: this.isShopTransit,
          shippingMethod: currShippingMethod
        })
        if (isShowMutexDialog) {
          this.updateState({
            key: 'shippingTypeDialog',
            value: true
          })
          this.assignState({
            shippingTypeChangeInfo: {
              ...this.shippingTypeChangeInfo,
              shippingMethod: currShippingMethod,
              mallCode,
              prevShippingMethod
            }
          })
          daEventCenter.triggerNotice({
            daId: this.isShopTransit ? '1-11-1-69' : '1-11-1-68'
          })
          return
        }

        if(this.forceUpdateShippingMethod.isUpdate) {
          this.assignState({
            forceUpdateShippingMethod: {
              shippingMethod: currShippingMethod,
              mallCode: mallCode,
              isUpdate: true
            }
          })
        }
        this.changeParamsStatus({
          type: 'shippingLimitInfo',
          params: { shippingLimitModalCount: 0 }
        })
        this.handleShippingSelect({
          scence,
          mallCode,
          transport_type: currShippingMethod.transport_type,
          item: currShippingMethod,
          modifyCart
        })
      }
    },
    // 切换货币单独获取运费险mall信息
    updateShippingInsurance(opt) {
      if (opt.mallList) opt.mallList = JSON.stringify(opt.mallList)
      schttp({
        url: '/api/checkout/shippingInsuranceMall/get',
        params: opt
      }).then(json => {
        const insuranceMallData = json?.insuranceMallData || {}
        const { mall_list = [] } = insuranceMallData
        if (!mall_list.length) return

        const mallInsuranceCheck = updateMallInsuranceCheck({
          preMallInsuranceCheck: this.mallInsuranceCheck,
          insuranceMallData,
          forcedShippingByAbt: this.forcedShippingByAbt
        })
        this.checkout.results.insuranceMallData = insuranceMallData || {}
        this.assignState({ mallInsuranceCheck: mallInsuranceCheck })

        //当险种(insurance_type)为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
        this.handleUseInsurance({
          mallInsuranceCheck,
          insuranceMallData,
          updataCalcu: true
        })
      })
    },
    paypalGaVaultBi(type, extraData = {}) {
      const ids = {
        expose_paypal_vaulting: '1-11-1-49',
        click_paypalvaulting_choose: '1-11-1-50',
        click_popup_vaulting_changeconfirm: '1-11-1-51'
      }
      if (!ids[type]) return
      daEventCenter.triggerNotice({
        daId: ids[type],
        extraData
      })
    },
    paypalSignAnalysis() {
      if (!this.checkout.results?.paymentMethods) return
      this.checkout.results.paymentMethods.forEach(item => {
        if (
          item.enabled == 1 &&
          item.code == 'PayPal-GApaypal' &&
          item.paymentSignUp &&
          item.paymentSignUp.length
        ) {
          this.paypalGaVaultBi('expose_paypal_vaulting')
        }
      })
    },
    async getShipppingFree() {
      //获取埋点数据
      if (this.checkout.results.carts.cartInfo) {
        const cartData = this.checkout.results.carts.cartInfo

        let mallShipParam = {}
        try {
          await this.getShippingFreeMall()
          const shippingContentInfo = this.shippingContentInfo

          const mallShippingAnalysisParam = handleMallShippingAnalysisParam({
            cartInfo: cartData,
            mallShippingInfo: shippingContentInfo
          })
          mallShipParam = handleMallShippingAnalysis(mallShippingAnalysisParam)

          if (!shippingContentInfo) {
            this.pageOnload(SaPageInfo)
            return
          }
        } catch (e) {
          console.log(e)
          this.pageOnload(SaPageInfo)
          return
        }

        //活动id和是否满足
        let promotion_method = ''
        let page_fromcart = ''
        let checkout_from = ''
        let checkout_total = ''
        if (window.sessionStorage?.getItem) {
          try {
            let cartPoints = window.sessionStorage.getItem('cartPoints')
            if (cartPoints) {
              cartPoints = JSON.parse(cartPoints)
              let cartSa = cartPoints.sa
              if (cartSa) {
                promotion_method = cartSa.promotion
                page_fromcart = cartSa.page_from

                checkout_from = cartSa.click_from
                  ? `${cartSa.click_from}\`${cartSa.button_name}`
                  : ''
                checkout_total = cartSa.checkout_total
              }
            }
            if(!!cartPoints?.checkout_from) checkout_from = cartPoints.checkout_from
          } catch (e) {
            console.log(e)
          }
        }

        let pageParam = {
          promotion: promotion_method,
          //取购物车的page_from
          page_fromcart,
          checkout_total,
          checkout_from,
          mall_shipping_method: mallShipParam?.mall_shipping_method || '',
          mall_origin_price: mallShipParam?.mall_origin_price || '',
          mall_is_fullshippingactivity:
            mallShipParam?.mall_is_fullshippingactivity || '',
          mall_step_fee: mallShipParam?.mall_step_fee || '',
          mall_charging_type: mallShipParam?.mall_charging_type || '',
          mall_num_price: mallShipParam?.mall_num_price || ''
        }

        Object.assign(SaPageInfo.page_param, pageParam)

        this.pageOnload(SaPageInfo)
      }
    },
    updateCalcuMallParam({ selectMallShip, mallInsuranceCheck, isInit }) {
      let switchQsFlag = this.switchQsFlagByAbt
      const mall_params = handleCalcuMallParam({
        selectMallShip,
        mallInsuranceCheck,
        switchQsFlag,
        isClickedQsFreight: this.isClickedQsFreight,
        qsFreightSelected: this.qsFreightSelected
      })

      if(isInit) {
        this.assignState({
          prevCalcuMallParams: mall_params
        })
        if(this.checkout.cacheInfo.mall_switch_qs_flag.used) {
          // 页面初始化时调用，需应用qs缓存
          // 目前只有单mall有该逻辑
          switchQsFlag =
            this.checkout?.cacheInfo?.mall_switch_qs_flag?.val?.[0]
              ?.switch_qs_flag || switchQsFlag
        }
      }
      shem_caculate_data.mall_params = mall_params
      this.assignState({ cacuMallParams: shem_caculate_data.mall_params })
    },
    // 当险种(insurance_type)为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
    handleUseInsurance({ mallInsuranceCheck, insuranceMallData, updataCalcu }) {
      const { isChange = false, insuranceCheck } = handleMallInsuranceChange({
        mallInsuranceCheck,
        insuranceMallData,
        mall_caculate_info: this.checkout?.mall_caculate_info
      })

      if (!isChange) return
      // mallInsuranceCheck 发生变化才进行下述操作
      this.assignState({ mallInsuranceCheck: insuranceCheck })
      this.updateCalcuMallParam({
        selectMallShip: this.defaultShipping,
        mallInsuranceCheck: insuranceCheck
      })

      if (!updataCalcu) return
      this.mallCacuTotal({
        opt: shem_caculate_data
      })
    },
    initGooglePayment: function () {
      const _this = this
      // 谷歌pay初始化-检测浏览器是否支持谷歌pay
      if (_this.checkout.results.paymentMethods) {
        $.each(_this.checkout.results.paymentMethods, function (index, item) {
          if (googlePayMethods.includes(item.code)) {
            if (item.enabled == 1) {
              googlePayment.onGooglePayLoaded(
                { createDom: false, code: item.code },
                function (valid) {
                  _this.googleValid = valid
                  if (!valid) {
                    Vue.set(
                      _this.checkout.results.paymentMethods[index],
                      'enabled',
                      0
                    )
                    if (googlePayMethods.includes(_this.status.cardPay)) {
                      _this.changeParamsStatus({
                        type: 'status',
                        params: { cardPay: '' }
                      })

                      shem_caculate_data.payment_id = ''
                      shem_caculate_data.payment_code_unique = ''

                      _this.mallCacuTotal({
                        opt: {
                          ...shem_caculate_data
                        }
                      })
                    }
                  }
                }
              )
            }
            return false
          }
        })
      }
    },
    insuranceCheckOpt: function (mall_code) {
      let mallInsuranceCheck = this.mallInsuranceCheck
      mallInsuranceCheck = mallInsuranceCheck.map(insurance => {
        if (insurance.mall_code == mall_code) {
          return Object.assign({}, insurance, {
            use_insurance: insurance.use_insurance == 1 ? 0 : 1
          })
        } else {
          return insurance
        }
      })
      this.assignState({ mallInsuranceCheck: mallInsuranceCheck })
      this.updateCalcuMallParam({
        selectMallShip: this.defaultShipping,
        mallInsuranceCheck
      })
      this.mallCacuTotal({
        opt: shem_caculate_data,
        loadingInfo: {
          show: true
        }
      })
    },
    handleDefauShippingInfo({ val, method, mall_code, index }) {
      if (!mall_code) return
      return val.map(item => {
        if (item.mall_code == mall_code) {
          return Object.assign({}, item, { shipping_method: method, index })
        } else {
          return item
        }
      })
    },
    async changeShipUpdateCalcu({ transport_type, scence, modifyCart }) {
      // 台湾店配地址
      if (this.storeAddressSite?.includes(this.SiteUID)) {
        this.changeParamsStatus({ type: 'status', params: { cardPay: '' } })
        shem_caculate_data.payment_code_unique = ''
        shem_caculate_data.payment_id = ''
        if (TRANSPORT_TYPE_SHOP.includes(transport_type)) {
          await this.getStoreAddress()
        }
        const countryValue =
          (this.checkout.default_address &&
            this.checkout.default_address.value.toUpperCase()) ||
          ''
        this.updatePayment({ value: countryValue })
      }

      var hashValue = location.hash
      hashValue = hashValue.replace('#', '')
      const hashList = [
        'addressList',
        'addressEdit',
        'addressAdd',
        'addressSupplement'
      ]
      // 是否在地址列表处触发的修改
      const isAddressType = hashList.includes(hashValue)
      this.mallCacuTotal({
        opt: shem_caculate_data,
        type: modifyCart ? 'modifyCart' : '',
        cb: res => {
          if (res.code != 0) {
            if (modifyCart) {
              this.resetMallCacuTotal()
              return
            }

            if (!scence && res.code == 300628) {
              // 所选方式不能用券
              this.handleShippingSelect({ scence: -1 })
            }
            this.handleCompareAfterAddAddress()
            return
          }
          if (
            res.code == 0 &&
            res?.info?.changeCodLocalCurrency?.isChange == 1 &&
            shem_caculate_data.currencyCode !=
              res?.info?.changeCodLocalCurrency?.codLocalCurrency
          ) {
            // cod支付后端转换货币后前端跟随转换对应货币
            this.mallCacuTotal({ opt: shem_caculate_data })
          }

          if ((scence == 1 || scence == 3) && res.code == 0) {
            // 当险种(insurance_type)为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
            this.updateStock(this.checkout?.mall_caculate_info?.outStockCarts)
            this.handleUseInsurance({
              mallInsuranceCheck: this.mallInsuranceCheck,
              insuranceMallData: this.checkout?.results?.insuranceMallData,
              updataCalcu: true
            })
          }
          this.handleCompareAfterAddAddress()
        },
        loadingInfo: {
          show: !isAddressType,
          duration: !scence ? 800 : 0
        },
        notTips: modifyCart ? true : false
      })
    },
    changeAllShip({ transport_type, prevMallParams, mallCode, scence = 0 }) {
      let mallShipIndex = []
      if (![0, 2].includes(scence)) {
        mallShipIndex = initMallShipIndex({
          mallShippingMethods: this.mallShippingMethods,
          has_shop_transit_address: !!this.storeData ? 1 : 0,
          orderInterceptByAbt: this.orderInterceptByAbt,
          prevMallParams: prevMallParams ?? [],
        })
      }
      if (scence == 2) {
        // 切换店配/宅配，切换所有mall运输方式
        mallShipIndex = handleMallShipIndex({
          mallShippingMethods: this.mallShippingMethods,
          orderInterceptByAbt: this.orderInterceptByAbt,
          transportType: transport_type,
          mallCode,
        })
      }

      const selectMallShip = handleMallShippingInfo(
        this.mallShippingMethods,
        mallShipIndex
      )
      this.updateCalcuMallParam({
        selectMallShip,
        mallInsuranceCheck: this.mallInsuranceCheck
      })
      this.assignState({ defaultShipping: selectMallShip })
    },
    changeSelectShip({ item, index, mallCode, prevMallParams }) {
      shem_caculate_data.mall_params = prevMallParams.map(mall => {
        if (mall.mall_code == mallCode) {
          return Object.assign({}, mall, {
            transport_type: item.transport_type,
            switch_qs_flag: handleCurrShipSwitchQsFlag({
              switchQsFlagByAbt: this.switchQsFlagByAbt,
              shippingMethod: item,
              isClickedQsFreight: this.isClickedQsFreight,
              qsFreightSelected: this.qsFreightSelected
            })
          })
        } else {
          return mall
        }
      })
      this.assignState({
        cacuMallParams: shem_caculate_data.mall_params,
        defaultShipping: this.handleDefauShippingInfo({
          val: this.defaultShipping,
          method: item,
          mall_code: mallCode,
          index: index
        })
      })
    },
    /**
     * 选择运输方式
     * scene
     * -1: 回退先前运输方式
     * 0: 切换当前mall运输方式
     * 1: 正常切换或者编辑地址重新获取接口
     * 2: 所有mall切换 店配/宅配运输方式
     * 3: 运输方式限制前置重新获取接口
     */
    handleShippingSelect({
      item,
      index,
      mallCode,
      transport_type,
      scence = 0,
      modifyCart
    }) {
      let prevMallParams = shem_caculate_data.mall_params
      // 重置切店配运输方式新增店配地址的状态数据，防止异常选中
      if (mallCode != this.selectStoreAddressShippingInfo?.mallCode) {
        this.assignState({
          selectStoreAddressShippingInfo: {
            shippingMethod: {},
            mallCode: '',
            isAdd: false
          }
        })
      }
      if (mallCode != this.forceUpdateShippingMethod?.mallCode) {
        this.assignState({
          forceUpdateShippingMethod: {
            shippingMethod: {},
            mallCode: '',
            isUpdate: false
          }
        })
      }

      // 运输方式限制时需要先替换成即将选中的
      const specialSelect = this.selectStoreAddressShippingInfo?.isAdd || this.forceUpdateShippingMethod?.isUpdate
      if(scence == -1) {
        prevMallParams = this.prevCalcuMallParams
      } else if (scence == 3 || specialSelect) {
        prevMallParams = prevMallParams?.map(mall => {
          if (mall.mall_code == mallCode)
            return Object.assign({}, mall, {
              transport_type: transport_type,
              switch_qs_flag: handleCurrShipSwitchQsFlag({
                switchQsFlagByAbt: this.switchQsFlagByAbt,
                shippingMethod: item,
                isClickedQsFreight: this.isClickedQsFreight,
                qsFreightSelected: this.qsFreightSelected
              })
            })
          return mall
        })
      }

      if (!scence) {
        this.changeSelectShip({ item, index, mallCode, prevMallParams })
      } else {
        this.changeAllShip({ transport_type, prevMallParams, mallCode, scence })
      }
      // 运输方式是否为店配
      const isStoreAddress = TRANSPORT_TYPE_SHOP.includes(transport_type)
      // 运输方式为店配，且店配地址不存在时，不走计价
      // 当无地址（宅配）进入下单页面的时候，店配是禁用的，能选择的运输方式只有宅配，因此添加地址后，一定会进入下面if中。
      if (!isStoreAddress || this.storeData?.status == 1) {
        this.changeShipUpdateCalcu({ transport_type, scence, modifyCart })
      }

      this.assignState({
        selectStoreAddressShippingInfo: {
          ...this.selectStoreAddressShippingInfo,
          isAdd: false
        },
        forceUpdateShippingMethod: {
          ...this.forceUpdateShippingMethod,
          isUpdate: false
        },
      })
    },
    unfold() {
      this.updatePaymentMethods({ folded_position: -1 })
    },
    // 更新支付方式
    updatePayment: async function (opt, cb) {
      if (this.functionHandlerIsLoaed) {
        return this.updatePaymentProxy(opt, cb)
      } else {
        try {
          await this.fetchFunctionHandler()
          this.updatePaymentProxy(opt, cb)
        } catch (error) {
          console.log(error)
          await this.fetchFunctionHandler()
          this.updatePaymentProxy(opt, cb)
        }
      }
    },
    selectShipMethod: function (id) {
      let targetAddress = this.checkout.address_list.find(
        item => item.addressId == id
      )
      if (targetAddress) {
        this.checkout.default_address = targetAddress
      }
    },
    // 会出现触发地址信息的时候，未触发计价请求，传参为上一次计价请求的参数，需要手动替换
    /**
     * params入参为当前点击想要切换的运输方式
     * mall_code(必传)
     * transport_type(必传)
     */
    getMallLists(params = {}) {
      if (!shem_caculate_data?.mall_params?.length) {
        return []
      }
      let mallList = shem_caculate_data.mall_params.map(
        ({ mall_code, transport_type }) => ({
          mallCode: mall_code,
          transportType: transport_type
        })
      )
      const { mall_code: curr_mall_code, transport_type: curr_transport_type } = params
      if(!!curr_mall_code) {
        mallList = mallList.map(mall => {
          if(mall.mallCode == curr_mall_code) {
            return {
              mallCode: curr_mall_code,
              transportType: curr_transport_type,
            }
          } else {
            return mall
          }
        })
      }
      return mallList
    },
    getStoreAddressDetail(params = {}) {
      const { countryId, state, stateId, city, cityId } =
        this.checkout.default_address
      const mallList = this.getMallLists(params)
      return schttp({
        method: 'POST',
        url: '/api/user/addressbook/storeAddress/get',
        data: {
          countryId,
          state,
          stateId,
          city,
          cityId,
          mallList,
          scenes: 1,
          transportCode: mallList?.[0]?.transportType
        }
      }).then(json => {
        if (json && json.code == 0 && json.info && json.info.result) {
          const result =
            Object.keys(json.info.result).length > 0 ? json.info.result : ''
          if (countryId == 209 && (!result || result.status != '1')) {
            this.assignState({ storeData: '' })
          } else {
            this.assignState({ storeData: result })
          }
        } else {
          this.assignState({ storeData: '' })
        }
      })
    },
    // 获取店配地址
    async getStoreAddress(params = {}) {
      await this.getStoreAddressDetail(params)
      const result = this.storeData
      if (!result || result.status === 2) {
        const addressInfo = this.checkout?.default_address || {}
        this.assignState({
          storeData: {
            ...addressInfo,
            status: 2 // 默认填充宅配地址数据
          }
        })
        this.emitStoreAddress('add', params)
      } else if (result.status != '1') {
        this.assignState({
          storeData: {
            ...result,
            status: 0
          }
        })
        this.emitStoreAddress('add', params)
      }
    },
    applyGiftCardGoBack: function (val) {
      const _this = this
      if (this.giftcard.cardNo && val == '') {
        shem_caculate_data.card_no = ''
        shem_caculate_data.card_pin = ''
        _this.mallCacuTotal({
          opt: shem_caculate_data,
          cb: function (res) {
            if (res.code == 0) {
              _this.changeParamsStatus({
                type: 'giftcard',
                params: {
                  usedAmount: '',
                  balance: '',
                  cardNo: ''
                }
              })

              $('html, body, .j-mshe-container').removeClass('mshe-scroll-hide')
              _this.changeParamsStatus({
                type: 'pageStatus',
                params: { giftCardDrawer: false }
              })
            }
          }
        })
      } else {
        $('html, body, .j-mshe-container').removeClass('mshe-scroll-hide')
        _this.changeParamsStatus({
          type: 'pageStatus',
          params: { giftCardDrawer: false }
        })
      }
    },
    showCouponTips() {
      this.isShowCouponTips = true
    },
    closeCoupon(type) {
      this.isShowCouponTips = false
      if (type === 'cancel') {
        this.$nextTick(() => {
          this.$refs.couponOptions.$refs.couponOptions.scrollIntoView()
        })
      }
    },
    // 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
    async createOrder(opt) {
      console.log('createOrder ----', shem_caculate_data, opt)
      if (this.functionHandlerIsLoaed) {
        return this.createOrderProxy(opt)
      } else {
        try {
          await this.fetchFunctionHandler()
          return this.createOrderProxy(opt)
        } catch (error) {
          console.log(error)
          await this.fetchFunctionHandler()
          return this.createOrderProxy(opt)
        }
      }
    },
    fetchFunctionHandler() {
      if (this.functionHandlerIsLoaed) return Promise.resolve()
      return new Promise(resolve => {
        import(
          /* webpackChunkName: "checkout-functionHandler-js" */ './functionHandler'
        ).then(
          ({
            createOrderProxy,
            createOrderFnProxy,
            updatePaymentProxy,
            resetPaymentInfoProxy
          }) => {
            this.createOrderProxy = createOrderProxy.bind(this)
            this.createOrderFnProxy = createOrderFnProxy.bind(this)
            this.updatePaymentProxy = updatePaymentProxy.bind(this)
            this.resetPaymentInfoProxy = resetPaymentInfoProxy.bind(this)
            this.functionHandlerIsLoaed = true
            resolve()
          }
        )
      })
    },
    async resetPaymentInfo(opts) {
      if (this.functionHandlerIsLoaed) {
        return this.resetPaymentInfoProxy(opts)
      } else {
        try {
          await this.fetchFunctionHandler()
          return this.resetPaymentInfoProxy(opts)
        } catch (error) {
          console.log(error)
          await this.fetchFunctionHandler()
          return this.resetPaymentInfoProxy(opts)
        }
      }
    },
    createOrderFn() {
      return this.createOrderFnProxy()
    },
    checkStoreAddress() {
      return new Promise(resolve => {
        // 宅配初始化店配页面数据但是没有 storeId
        if (!this.storeData?.storeId) {
          this.loadingShow = false
          this.emitStoreAddress('add')
          return resolve(false)
        }
        

        fetchCheckStoreAddressApi({
          ...(this.storeData || {}),
        }).then(res => {
          const { code = 0, tips = '' } = res
          if (code == 0) {
            resolve(true)
            return
          }

          if (code == 401974) {
            this.$refs.addressCom.twAddressTips = true
          } else if (code == 401987) {
            this.$toast(tips)
          } else {
            this.emitStoreAddress('edit')
          }

          this.loadingShow = false
          resolve(false)
        })
      })
    },
    // 默认选择支付
    getPaymentSuggestion: function () {
      const _this = this
      let target = null

      // URL上的当前支付方式编码
      let urlPaymentCode = ''
      if (this.isClient) {
        urlPaymentCode = getQueryString({ key: 'paymentCode' }) || ''
      }

      if (this.checkout?.results?.paymentMethods) {
        const lastPayment = this.checkout?.results?.paymentMethods?.filter(item => {
          // 进入下单页面，需要根据购物车选择的bnpl支付方式选择默认的支付方式
          if (!this.IS_RW && urlPaymentCode) {
            return item.code === urlPaymentCode && item.enabled == 1
          }
          return item.last_payment && item.last_payment == 1 && item.is_display == 1 && item.enabled == 1
        })
        if (lastPayment && lastPayment[0]) {
          target = lastPayment[0]
        }
      }

      if (!target) {
        if (this.status.cardPay) {
          this.checkout.paymentSuggestion = ''
          this.changeParamsStatus({ type: 'status', params: { cardPay: '' } })
          shem_caculate_data.payment_id = ''
          shem_caculate_data.payment_code_unique = ''
        }
        return
      }
      let lastPaymentCode = target.code
      if (lastPaymentCode != 'cod') {
        return
      }
      // 下单币种不在默认支付方式支持的币种列表范围时，取消该支付方式默认勾选逻辑，改由用户手动选择支付方式；也不执行强制币种转换逻辑；
      const { isChangeCurrency } = handleSwitchCurrency({
        currencySupport: this.currencySupport,
        currency: this.checkout.mall_caculate_info?.orderCurrency?.code || '',
        countryCode: this.checkout.default_address?.value || '',
        paymentCode: lastPaymentCode
      })

      if (isChangeCurrency) return false

      if (
        (lastPaymentCode == 'ebanx-card' || lastPaymentCode == 'ebanx-spei') &&
        !_this.checkout.default_address.countryId == 137
      ) {
        return false
      }
      if (
        lastPaymentCode == 'cod' &&
        !_this.checkout.mall_caculate_info.isSupportCod
      ) {
        return false
      }

      _this.checkout.paymentSuggestion = lastPaymentCode

      _this.resetDefaultMethods({
        action: () => {
          (_this.$refs.paymentOptions?.choosePayment || _this.choosePayment)({
            item: target,
            isPaymentSuggestionTriggerClick: true
          })
        }
      })
    },
    clickQiwiPay: function (qiwiPayPhone) {
      this.qiwiPayPhone = qiwiPayPhone
      this.createOrderFn()
    },
    // 清除无货商品
    soldOutCartsDelete(data) {
      if (
        this.checkout.results.carts.carts.length ==
          this.checkout.mall_caculate_info.outStockCarts.length ||
        data?.carts?.length == 0
      ) {
        history.back()
      } else {
        this.handleCartsStatus(data)
      }
    },
    productLimitHandle() {
      this.updateStock()
      this.updateCart()
    },
    filterApplePayLogoUrl() {
      let applePayChannel = this.checkout.applePayConfig?.applePayChannel || applePayConfig.applePayChannel
      let apple_pay_map = this.checkout.applePayConfig?.apple_pay_map ?? {}
      let version = 3
      if (window.ApplePaySession) {
        version = 14
        while (!window.ApplePaySession.supportsVersion?.(version)) {
          version--
        }
      }
      this.checkout.results.paymentMethods = this.checkout.results.paymentMethods.map(item => {
        if (applePayChannel.indexOf(item.code) > -1) {
          let supportedNetworks = []
          item.card_logo_list = item.card_logo_list?.filter?.(logo => {
            const cardInfo = apple_pay_map[logo]
            // 如果版本高于当前applepay支持的版本，或者apple_pay_map中无对应的版本信息，则不展示
            if (cardInfo && cardInfo.sdk_version && Number(cardInfo.sdk_version) <= version) {
              supportedNetworks.push(cardInfo.name)
              return true
            }
            return false
          }) || []
          if (this.checkoutAbtInfo?.ApplepayDropoffOptimization?.param?.ApplepayCardlogoShow != 'Show') {
            item.card_logo_list = []
          }
          item.supportedNetworks = supportedNetworks
          item.version = version
        }
        return item
      })
    },

    initApplePay: function (paymentMethods) {
      const newArr = paymentMethods.slice(0)
      // applepay初始化-检测浏览器是否支持applepay
      if (!(window.ApplePaySession && ApplePaySession.canMakePayments())) {
        if (paymentMethods && paymentMethods.length) {
          const applePayMethod = this.checkout.applePayConfig?.applePayChannel || applePayConfig.applePayChannel
          for (let index = paymentMethods.length - 1; index >= 0; index--) {
            if (applePayMethod.includes(paymentMethods[index].code)) {
              this.checkout.results.paymentMethods.splice(index, 1)
              newArr.splice(index, 1)
            }
          }
        }
      } else {
        this.filterApplePayLogoUrl()
      }
      return newArr
    },
    handleAddressSelectd: function (address, index) {
      daEventCenter.triggerNotice({ daId: '1-11-1-128' })
      this.$refs.addressCom.handleAddressSelectd(address, index)
    },
    handleAddressEdit: function (address) {
      this.$refs.addressCom?.handleAddressEdit(address)
    },
    handleAddressAdd: function (opt = {}) {
      this.$refs.addressCom?.handleAddressAdd(opt)
    },
    handleAddressChanged() {
      this.isNeedAgreenPrivacy()
    },
    // 筛选出要显示的置灰地址
    filteGrayAddress: function (addressListGray) {
      const sourceGray = []
      addressListGray.forEach(item => {
        if (lang == 'uk' && item.countryId == '104') sourceGray.push(item)
        if (
          ['ma', 'ar', 'ru', 'it', 'de', 'es'].includes(lang) &&
          ['100', '137'].includes(item.countryId)
        )
          sourceGray.push(item)
      })
      return sourceGray
    },
    addressComLoad(conponentName) {
      return new Promise(resolve => {
        if (this.$refs.addressCom?.$refs?.[conponentName]) {
          resolve()
        } else {
          this.asyncComponentLoadedStatus.addressCommonIsLoaded = true
          this.updateState({
            key: 'loading',
            value: true
          })
          let addressComInterval = setInterval(() => {
            if (this.$refs.addressCom?.$refs?.[conponentName]) {
              window.clearInterval(addressComInterval)
              this.updateState({
                key: 'loading',
                value: false
              })
              console.log('loading false')
              resolve()
            } else {
              this.updateState({
                key: 'loading',
                value: true
              })
            }
          }, 200)
        }
      })
    },
    async emitStoreAddress(type, params = {}) {
      await this.addressComLoad('storeAddress')
      const _this = this
      this.clickStoreAddress({
        type: type,
        vm: _this.$refs.addressCom.$refs.storeAddress,
        extraData: {
          mallList: this.getMallLists(params),
          pageSource: 'checkout',
        }
      })
    },
    confirmNotUseCoupon() {
      this.clearCouponUpdateCalculator()
    },
    async editDefaultAddress() {
      await this.addressComLoad('addressVue')
      if (!this.$refs.addressCom?.$refs?.addressVue) {
        return
      }
      this.$refs.addressCom.addressShow = true
      this.$refs.addressCom.$refs.addressVue.updateUI(
        'edit',
        this.checkout.default_address,
        () => {
          this.$refs.addressCom.$refs.addressVue?.scrollPassport?.(
            this.checkout.default_address.countryId
          )
        }
      )
      this.exposeAddressFrom({
        page_type: 'edit',
        activity_from: 'checkout_active'
      })
    },
    klarna: function (payment_method, container = '') {
      if (this.showWidgetForm != 1) {
        return
      }
      let _this = this
      let paymentMethod = payment_method || this.status.cardPay
      if (window.KlarnaSDKIsLoad && this.payInlineSessionInfo[paymentMethod]) {
        try {
          Klarna.Payments.init({
            client_token: this.payInlineSessionInfo[paymentMethod].clientToken
          })
          Klarna.Payments.load(
            {
              container:
                container.length > 0
                  ? container
                  : `#${paymentMethod}-payments-container`,
              payment_method_category:
                this.payInlineSessionInfo[paymentMethod].paymentMethodCategory
            },
            res => {
              this.showWidgetForm = res.show_form ? 2 : 0
              if (!res.show_form) {
                this.recordPayLineErr('inline/klarna/start', res)
              }
            }
          )
        } catch (e) {
          _this.showWidgetForm = 0
          this.recordPayLineErr('inline/klarna/start', { err: e })
        }
      }
    },
    klarnaLoadWidget: function () {
      if (gbCommonInfo.RELOAD_KLARNAL_WIDGET_SWITCH != 'on') {
        return
      }
      let payment_code_unique = shem_caculate_data.payment_code_unique
      let amount =
        this?.checkout?.mall_caculate_info?.grandTotalPrice?.amount ||
        this?.checkout?.results?.carts?.cartInfo?.totalPrice?.amount
      let country = this?.checkout?.default_address?.value || ''
      if (!(amount && country) || this.showWidgetForm < 2) {
        return
      }

      if (
        window.KlarnaSDKIsLoad &&
        this.payInlineSessionInfo[payment_code_unique]
      ) {
        let purchaseData =
          this.payInlineSessionInfo[payment_code_unique].purchaseData
        let amountFixed = amount.toString().split('.')[1].length
        let orderAmount = Number(
          (Number(amount) * Math.pow(10, amountFixed)).toFixed(0)
        )
        let newPurchaseData = {
          purchase_country: purchaseData.purchase_country,
          purchase_currency: purchaseData.purchase_currency,
          locale: purchaseData.locale,
          order_amount: orderAmount,
          order_tax_amount: 0,
          order_lines: [
            {
              type: 'physical',
              reference: 'checkout-reference-' + Date.now(),
              name: 'checkout-name-' + Date.now(),
              quantity: 1,
              unit_price: orderAmount,
              tax_rate: 0,
              total_amount: orderAmount,
              total_discount_amount: 0,
              total_tax_amount: 0
            }
          ]
        }

        Klarna.Payments.load(
          {
            container: `#${payment_code_unique}-payments-container`,
            payment_method_category:
              this.payInlineSessionInfo[payment_code_unique]
                .paymentMethodCategory
          },
          newPurchaseData,
          function (res) {
            console.log('info:' + res)
          }
        )
      }
    },
    klarnaAsyncCallback: function () {
      window.KlarnaSDKIsLoad = true
      this.klarna()
    },
    giftCardRemove() {
      this.$refs.giftCardPage?.cancelGiftCard()
      this.applyGiftCardGoBack('')

      this.giftCardLimit.show = false
      this.giftCardLimit.clickConfirm = false
      daEventCenter.triggerNotice({
        daId: '1-11-1-44'
      })
    },
    giftCardConfirm() {
      this.giftCardLimit.verify = 1
      this.giftCardLimit.show = false
      this.giftCardLimit.clickConfirm = true
      this.createOrder()
    },
    initCybersource: function () {
      if (window.GB_cybersource_uuid) return
      window.TPM?.run({
        marketing: 'Cybersource',
        method: '_loadCybersource'
      })
    },
    /**
     * @description 南非特殊商品弹窗将特殊商品移至收藏夹后的处理
     * @date 2022-01-10 15:45
     */
    handleUpdateCheckoutStatus(data) {
      if (!data?.carts?.length) {
        history.back()
      } else {
        this.handleCartsStatus(data)
      }
    },
    handleCartsStatus(data) {
      Vue.set(this.checkout.results.carts, 'carts', data?.carts || [])
      Vue.set(this.checkout.results.carts, 'mall_list', data?.mall_list || [])
      this.getCartPromotionInfo()

      this.mallCacuTotal({
        opt: shem_caculate_data,
        cb: res => {
          if (res.code != 0) {
            this.resetMallCacuTotal()
          }

          this.productLimitHandle()
        },
        notTips: true,
        type: 'modifyCart'
      })
    },
    // 初始化一站多合规信息
    async getMixPrivacyAbt() {
      const { SilentloginPrivacy, PlaceOrderNationPrivacy } =
        this.checkoutAbtInfo
      this.mixPrivacyAbt.loginPrivac = SilentloginPrivacy?.param || 'off'
      this.mixPrivacyAbt.placeOrder = PlaceOrderNationPrivacy?.param || 'no'
      this.isNeedAgreenPrivacy()
    },
    // 查询当前选择的地址id是否需要同意协议
    async isNeedAgreenPrivacy() {
      const locationId = this.checkout.default_address.countryId || ''
      const { loginPrivac, placeOrder } = this.mixPrivacyAbt
      if (!(loginPrivac == 'mix' && placeOrder == 'yes')) return
      let { newPrivacyCache } = await import('../login/util')

      if (newPrivacyCache.get(locationId) == 1) {
        this.mixPrivacy.needPop = false
        return
      }
      let { checkLocationClauseSer, checkMemeberCluaseSer } = await import(
        '../login/service'
      )
      const queue = [
        checkLocationClauseSer({ locationId }),
        checkMemeberCluaseSer({ locationId })
      ]
      const [location, member] = await Promise.all(queue)

      const clauseList = member?.info?.clause_list || []
      const privacyType = location?.info?.clause_country_type
      // 用户隐私协议未全部同意并且当前地区为强合规
      if (clauseList.some(v => v.clause_opt == 0) && privacyType == 1) {
        this.mixPrivacy.needPop = true
        return
      }
      this.mixPrivacy.needPop = false
    },
    handleMixAgreen() {
      this.mixPrivacy.needPop = false
      this.mixPrivacy.show = false
      this.createOrder()
    },
    handleMixClose() {
      this.mixPrivacy.show = false
    },
    mbwayConfirmSa() {
      daEventCenter.triggerNotice({ daId: '1-11-1-55' })
    },
    template: _commonTemplate,
    googlePayInit: async function () {
      if (window.googlePayment) return Promise.resolve()
      let GooglePay = await import(
        'public/src/pages/checkout/components/googlePay'
      )
      GooglePay = GooglePay.default
      // eslint-disable-next-line require-atomic-updates
      window.googlePayment = new GooglePay({ scene: 'Checkout' })
      var _this = this

      GooglePay?.clearAllListener?.()

      appEventCenter.$on('isGooglePayLoading', state => {
        _this.loadingShow = !!state
      })
      appEventCenter.$on('showGooglePay', ({ code } = {}) => {
        googlePayment.getConfig({
          code,
          cb: () => {
            // fix 偶现gp按钮未展示问题修复
            googlePayment.onGooglePayLoaded(
              { createDom: true, code },
              valid => {
                _this.googleValid = valid
              }
            )
            _this.googlePay.show = true
          }
        })
      })

      appEventCenter.$on('cancelGooglePay', (options = {}) => {
        if (!options?.manualClose) {
          this.loadingShow = false
        }
        _this.cancelGoogle(!options?.manualClose)
      })

      appEventCenter.$on('errorGooglePay', (msg, extraInfo = {}) => {
        const { billno, res = {} } = extraInfo || {}
        _this.$nextTick(() => {
          _this.loadingShow = false
          _this.showMaskTips({ text: msg, cb: () => {
            if (res?.code == '300317') {
              replacePaySuccessReferer()
              window.location.href = host + langPath + '/pay/result/success?billno=' + billno
              return
            }
            window.location.href = host + langPath + '/user/orders/detail/' + billno
          } })
        })
      })
      return
    },
    cancelGoogle: function (autoDisplay) {
      this.googlePay.show = false
      if (!autoDisplay) {
        const billno = googlePayment?.orderInfo?.billno || ''
        if (!billno) {
          window.location.href = host + langPath + '/user/orders/list'
        }
        window.location.href = host + langPath + '/user/orders/detail/' + billno
      }
    },
    async initSupportInfo() {
      const MAX_GUIDE_LOGO_COUNT = 6
      const { paymentMethods = [], checkout_guide_logo = [] } =
        this.checkout?.results || {}
      const laterGuideLogo = (paymentMethods || [])
        .filter(v => v.enabled && v.is_display && v.logo_url)
        .slice(0, MAX_GUIDE_LOGO_COUNT - checkout_guide_logo.length)
      const guideLogoList = [...checkout_guide_logo, ...laterGuideLogo].map(
        v => ({
          url: v.url || v.logo_url || '',
          id: v.id
        })
      )
      this.updateCheckoutGuideLogoList(guideLogoList)
      if (this.isShowSupportInfo) {
        const logoInfo = await fetchPayDomainLogoApi({
          page_type: 4
        })
        this.updatePaymentLogoInfo(logoInfo)
      }
    },
    handleCheckoutDialogLoaded(v) {
      if (v && !this.asyncComponentLoadedStatus.checkoutDialogIsLoaded) {
        this.asyncComponentLoadedStatus.checkoutDialogIsLoaded = true
        this.updateState({
          key: 'loading',
          value: true
        })
      }
    },
    handlePaymentOptionsOper({ type } = { type: '', data: {} }) {
      if (type === 'bin-discount-article') {
        if (!this.asyncComponentLoadedStatus.cardBinDiscountArticleIsLoaded) {
          this.asyncComponentLoadedStatus.cardBinDiscountArticleIsLoaded = true
          this.updateState({
            key: 'loading',
            value: true
          })
        }
        this.visibleCardBinDiscountArticle = true
      }
    },
    handleAddressSelect() {
      location.hash = '#addressList'
    },
    async handleEditStoreAddress(params = {}) {
      await this.emitStoreAddress('edit', params)
      // TODO 因店配地址组件层级太低，会被drawer盖住，所以需要强行改变店配地址组件层级
      const hackZIndex = +window.getComputedStyle?.(
        document.querySelector('.shipping-address-limit__drawer')
      )?.zIndex
      if (hackZIndex) {
        this.forceUpdateStoreAddressModelZIndex(hackZIndex + 1)
      }
    },
    forceUpdateStoreAddressModelZIndex(zIndex) {
      const targets =
        document.querySelectorAll('.j-address-vue .j-address-box') || []
      targets.forEach(target => {
        target.style.zIndex = zIndex || ''
      })
    },
    cancelChangeShippingType() {
      const { prevShippingMethod = {}, mallCode = '' } =
        this.shippingTypeChangeInfo || {}
      this.handleShippingSelect({
        transport_type: prevShippingMethod.transport_type,
        scence: 2,
        mallCode
      })
      daEventCenter.triggerNotice({
        daId: this.isShopTransit ? '1-11-1-61' : '1-11-1-63'
      })
    },
    changeShippingType() {
      if (!this.isShopTransit) this.assignState({ changeShopType: true })
      // 埋点要在selected前上报，isShopTransit值会进行修改
      daEventCenter.triggerNotice({
        daId: this.isShopTransit ? '1-11-1-64' : '1-11-1-62'
      })
      const { shippingMethod, mallCode = '' } =
        this.shippingTypeChangeInfo || {}
      this.handleShippingSelect({
        transport_type: shippingMethod.transport_type,
        scence: 2,
        mallCode
      })
    },
    handleShippingLimitAddressClose() {
      // 如果是选中的是店配运输地址，则在关闭限制弹窗后需重置店配地址组件层级
      if (this.shippingLimitInfo?.isStoreAddress) {
        this.forceUpdateStoreAddressModelZIndex()
      }
    },
    handleBankSelectInVm(item){
      this.updateCapitecDialog({
        phone: this.checkout?.default_address?.tel,
        countryId: this.checkout?.default_address?.countryId
      })
      this.handleBankSelect(item)
    },
    handleCapitecDialogEventInVm(evt){
      // !evt.isDlocalPse && reportBankDialog(evt)
      this.handleCapitecDialogEvent(evt)
    },
    /**
     * @description: 获取下单页面的权益点
     * @return {*}
     */    
    getInterestsData() {
      if (this.interestsDataHasReport) {
        return
      }
      this.interestsDataHasReport = true

      const Casual = [1, 2, 3].includes(this.togetherBuyLocation) // 是否有随手购
      const SHEIN_Club = !!this.primeProductList?.length // 是有付费会员
      const regain_coupon = (Object.values(this.integrityPromotionInfo) || [])?.some(item => item?.type_id == '21') || false // 是否有返劵
      const saver = this.showXtra // 有超省卡
      const freeshiping = this.isFreePrice // 有免邮
      const quickship = !!this.checkout?.results?.carts?.carts?.filter(item => item.real_quick_ship == 1)?.length // 有quickship时效商品

      const interestsData = []
      if (Casual) { interestsData.push('Casual') } // 随手购
      if (SHEIN_Club) { interestsData.push('SHEIN_Club') } // 随单购
      if (regain_coupon) { interestsData.push('regain_coupon') } // 订单返劵
      if (saver) { interestsData.push('saver') } // 超省卡
      if (freeshiping) { interestsData.push('freeshiping') } // 免邮
      if (quickship) { interestsData.push('quickship') } // 时效商品
      
      daEventCenter.triggerNotice({
        daId: '1-11-1-203',
        extraData: {
          interests: interestsData.join('`')
        }
      })

      setTimeout(()=> {
        this.interestsDataHasReport = false
      }, 1300)
    },
    handleCompareAfterAddAddress() {
      if (!this.isHasAddressInEnterCheckout && this.isNoAddressEnterCheckoutByAbt && sessionStorage.getItem('isNeedCompareAfterAddress')) {
        const notAddressSelectedData = JSON.parse(sessionStorage.getItem('notAddressSelectedData') || '{}')
        const showAddressDrawerSource = sessionStorage.getItem('showAddressDrawerSource') || ''
        const { payment_code_unique, mall_params } = shem_caculate_data || {}
        const { originPayCode, originShippingRelated = [], originTotalPrice } = notAddressSelectedData
        const nowTotalPrice = this.price?.grandTotalPrice?.amount
        const checkoutBtnSource = showAddressDrawerSource && showAddressDrawerSource == 'place_order'
        const mallList = this.mallShippingMethods?.mall_list || []
        
        // 对比运输相关的数据
        const shippingRelated = mall_params.every(item => {
          let shippingCanUse = true
          // 无地址的时候，存储的运输方式数据（mall_code、transport_type、time_text）
          const oldShippingData = originShippingRelated.find(_ => _.mall_code ==  item.mall_code) || {}
          const oldAndNewShippingSame = oldShippingData.transport_type == item.transport_type
        
          // 如果前后的运输方式一致，需要判断运输方式在新的接口数据里面是否是可以使用的
          if (oldAndNewShippingSame) {
            const currentNewShippingMethods = mallList.find(_ => _.mall_code == item.mall_code) || {}
            shippingCanUse = !!(currentNewShippingMethods?.shipping_methods?.find(_ => _.transport_type == item.transport_type)?.is_available)
          }

          if (!oldAndNewShippingSame || !shippingCanUse) {
            return false
          }
          // 两个运输方式一致，并且新运输方式可用，再对比地址从无到有的时候，时效文案
          const shippingTypeTimeText = this.collectShippingTimeText[item.mall_code]?.find(val => val.transport_type == item?.transport_type)
          return oldShippingData.time_text == shippingTypeTimeText?.time_text
        })

        const isAllAccord = originPayCode == payment_code_unique && originTotalPrice == nowTotalPrice && shippingRelated
         
        sessionStorage.removeItem('notAddressSelectedData')
        sessionStorage.removeItem('showAddressDrawerSource')
        sessionStorage.removeItem('isNeedCompareAfterAddress')
        this.assignState({ isHasAddressInEnterCheckout: true })

        if (!!checkoutBtnSource && !!payment_code_unique) {
          if (isAllAccord) {
            // this.$refs.checkoutFooterRef?.createOrder()
            this.createOrder()
          } else {
            daEventCenter.triggerNotice({
              daId: '1-11-1-217',
            })
            this.$toast(this.language.SHEIN_KEY_PWA_31042, 3000)
          }
        } else if (!checkoutBtnSource && !payment_code_unique && !isAllAccord){
          daEventCenter.triggerNotice({
            daId: '1-11-1-217',
          })
          this.$toast(this.language.SHEIN_KEY_PWA_31042, 3000)
        }
      }
    },
    handleCvvResult(cvv) {
      this.updateState({
        key: 'tokenCvv', value: cvv
      })
      this.createOrder()
    },

    ChangeIsShowCvvDrawer() {
      this.assignState({ 
        isShowInstallmentListDrawer: false,
        isShowCvvDrawer: false 
      })
    },

    clickDes(item) {
      const des = this.getPaymentDes(item)
      des && this.showMaskTips(des)
    },

    choosePayment(opts) {
      const { item, isPaymentSuggestionTriggerClick, index } = opts
      if (item.isAllBusiness) return this.showMaskTips(item.gray_description)

      if (item && item.enabled != 1) {
        this.clickDes(item)
        return
      }
      const { folded_position, originFoldedPosition } =
        this.checkout.results || {}
      if (folded_position === -1 && opts.index >= originFoldedPosition) {
        daEventCenter.triggerNotice({ daId: '1-11-1-72' })
      }
      this.clickPayment({
        id: item.id,
        code: item.code,
        item: item,
        isPaymentSuggestionTriggerClick,
        paypalGaVault: this?.paypalGaVault || {},
        afterPayVault: this?.afterPayVault || {},
        onlinePayDiscountInfoValue: this.price?.onlinePayDiscountInfo?.onlinePayDiscountValue?.[item?.code] || null,
        idx: index
      })
    },
    handleUsePrePay () {
    },
    toggleTotal() {
      this.assignState({ isShowPriceDetail: !this.isShowPriceDetail })
    },
    handlerChangePriceDetail() {
      this.assignState({ isShowPriceDetail: !this.isShowPriceDetail })
    },
    handlePreUnPay (order = {}, { skipFreezeOperate } = {}) {
      const self = this
      self.setPrePayCheckoutInfo({
        ddcInfo: {
          formActionUrl: self.ddcFormParams?.formActionUrl || '',
          jwt: self.ddcFormParams?.jwt || ''
        },
        necessaryOrderInfo: {
          totalPrice: order.totalPrice || {},
          sub_billnos: order.sub_billnos || [],
          billno: order?.billno || ''
        }
      })
      // self.setTokenPayCvv(self.tokenCvv)
      const params = {
        routeId: self.prePayUnPayParams?.routeId || '',
        card_bin: self.prePayUnPayParams?.cardBin || '',
        last_four_no: self.prePayUnPayParams?.lastFourNo || '',
        expire_month: self.prePayUnPayParams?.expireMonth || '',
        expire_year: self.prePayUnPayParams?.expireYear || '',
        cardno: self.prePayUnPayParams?.cardNumber || '',
        cpfNumber: self.prePayUnPayParams?.cpfNumber || '',
        cardHolderName: self.prePayUnPayParams?.cardHolderName || '',
        rememberCard: self.prePayUnPayParams?.rememberCard || '',
        cvv: self.prePayUnPayParams?.cvv || '',
        countryCode: self.prePayUnPayParams?.countryCode || '',
        billno: order?.billno || '',
        payment_method: order?.payment_method || '',
        paymentCode: self.prePayUnPayParams?.paymentCode || '',
        installments: self.prePayUnPayParams?.installments || '',
        cardType: self.prePayUnPayParams?.cardType || '',
        autoBindCard: self.prePayUnPayParams?.autoBindCard || '',
      }

      // 二次支付卡bin冻结流程
      if (!skipFreezeOperate && self.cardBinFreezeParams && self.prePayCheckoutInfo.billno) {
        params.freezeParamsStr = JSON.stringify(self.cardBinFreezeParams || {})
      }

      self.prePayMergeState('clickContinueResultInfo', {
        failure_type: 'rear_end', // 失败类型 front_end/rear_end
        instalment_num: self.prePayUnPayParams?.installments || '',
        is_remember: self.prePayUnPayParams?.rememberCard ? '1' : '0',
        order_id_list: order?.sub_billnos?.map?.(v => v.sub_billno).join(',') || '', // 每个子订单号之间用 隔开
        order_no: order?.billno || '',
        payment_method: order?.payment_method || '',
        scene: 'new',
        uorder_id: order?.billno || '', // 关联订单号 
      })

      // const clickContinuePayData = {
      //   failure_reason: '', // 失败原因 属性值格式为：{code:xxx,result:xxx,type:xxx}，其中result（哪里出错）和type（什么错误类型）两部分的规则如下： 当卡号错误时，result的值为card_num
      //   failure_type: 'rear_end', // 失败类型 front_end/rear_end
      //   instalment_num: self.prePayUnPayParams?.installments || '',
      //   is_remember: self.prePayUnPayParams?.rememberCard ? '1' : '0',
      //   order_id_list: (order?.sub_billnos || []).join(','), // 每个子订单号之间用 隔开
      //   order_no: order?.billno || '',
      //   payment_method: '',
      //   prime_deduction: '', // 付费会员抵扣金额
      //   scene: '', // 场景类型 新卡上报new，非新卡上报token
      //   status: '',
      //   pay_response_time: '',
      //   stay_time: '', // 单位是“秒” 记录从进入卡支付页面到点击continue按钮的时长
      //   token_id: '', // token_id
      //   uorder_id: order?.billno || '', // 关联订单号 
      // }

      const handleTokenPayFunc = (params) => {
        self.prePayMergeState('clickContinueResultInfo', {
          stay_time: parseInt((Date.now() - self.pageReadyTime) / 1000)
        })
        self.handlePayForPreCheckout({ 
          params,
          onPayComplete: ({ status, result }) => {
            const { host, langPath } = gbCommonInfo || {}
            const { billno } = order || {}

            self.prePayMergeState('clickContinueResultInfo', {
              failure_reason: JSON.stringify({
                code: result?.code == 0 && result?.info?.error_code ? result?.info?.error_code : result?.code || '',
                result: result?.info?.result || '0'
              })
            })
            self.reportClickContinueResult(self.clickContinueResultInfo)

            if (status === 'success') {
              replacePaySuccessReferer()
              window.location.href = `${host}${langPath}/pay/result/success?billno=${billno}&type=${order?.payment_method || ''}&gatewayPayNo=${result?.info?.gatewayPayNo || ''}`
            } else if (status === 'pending') {
              window.location.href = `${host}${langPath}/pay/result/pending?billno=${billno}`
            } else if (status === 'fail') {
              self.loadingShow = false
              self.assignState({
                createOrderLoading: false
              })
              if (result?.code == '300317') {
                replacePaySuccessReferer()
                window.location.href = `${host}${langPath}/pay/result/success?billno=${billno}&type=${order?.payment_method || ''}&gatewayPayNo=${result?.info?.gatewayPayNo || ''}`
                return
              }
              // 10119005 为卡bin优惠冻结操作自定义的错误码
              if (result?.code == '10119005') {
                self.prePayMergeState('prePayBinFreezeFailConfig', {
                  visible: true,
                  closeCb () {
                    self.prePayMergeState('prePayBinFreezeFailConfig', {
                      visible: false,
                      closeCb: () => {}
                    })
                    window.location.href = `${langPath}/user/orders/detail/${billno}`
                  },
                  confirmCb () {
                    self.prePayMergeState('prePayBinFreezeFailConfig', {
                      visible: false,
                      confirmCb: () => {}
                    })
                    self.prePayEventBus.createOrder?.emit?.({ skipFreezeOperate: true })
                  }
                })
                return
              }
              self.prePayMergeState('prePayFailGuide', {
                visible: true,
                failText: result?.info?.show_error_msg || result?.info?.error_msg || result?.tips || self.language.SHEIN_KEY_PWA_30525,
                type: 'payError',
                hasGuide: result.info?.is_guide == 1,
                closeCb: function ({ isActivelyClose } = {}) {
                  if (isActivelyClose) {
                    // daEventCenter.triggerNotice({
                    //   daId: '1-19-1-5',
                    //   extraData: {
                    //     error_code: result?.info?.error_code || '',
                    //     order_no: billno,
                    //     order_id_list: order.sub_billnos?.map(v => v.sub_billno).join(','),
                    //     uorder_id: order.billno
                    //   },
                    // })
                    window.location.href = `${langPath}/user/orders/detail/${billno}`
                  } else {
                    // daEventCenter.triggerNotice({
                    //   daId: '1-19-1-4',
                    // })
                    window.location.href = `${langPath}/user/orders/detail/${billno}?showpaymethods=1`
                  }
                },
                confirmCb: async function () {
                  self.prePayMergeState('prePayFailGuide', {
                    visible: false,
                    closeCb: () => {},
                    confirmCb: () => {}
                  })
                  self.prePayMergeState('prePayCheckoutInfo', { billno: order?.billno || '' })
                  const bin = self.prePayUnPayParams?.cardNumber?.substring?.(0, 8) || ''
                  const unPayRoutingInfo =  await self.handleQueryUnPayPreRoutingInfo({
                    bin,
                    routeCode: self.status.cardPay,
                    paymentMethod: self.status.cardPay,
                    orderCurrency: self.checkout.mall_caculate_info.orderCurrency.code,
                    countryCode: self.prePayUnPayParams?.countryCode,
                    billno: order?.billno || ''
                  })
                  self.prePayUpdateState('cardBinRoutingInfo', unPayRoutingInfo.routingInfo || {})

                  const currentInstallmentList = unPayRoutingInfo.installments || [
                    {
                      stage_num: 1,
                      rate: 0,
                      installment_amount: self.checkout.mall_caculate_info?.grandTotalPrice?.amountWithSymbol || '',
                      installment_fee_numeric: 0
                    }
                  ]
                  // 原选择的分期数是否在新的分期信息列表中，是的话可以保留原选中分期数，如果只有一条分期信息，则默认选中
                  const newInstallments = currentInstallmentList.find(v => v.stage_num === self.prePayUnPayParams.installments) ? 
                    self.prePayUnPayParams.installments : currentInstallmentList.length === 1 ? currentInstallmentList[1].stage_num : -1

                  self.changeCardBinDiscountInfoData(unPayRoutingInfo.routingInfo?.bin, unPayRoutingInfo.cardBinDiscountInfo)

                  self.prePayUpdateState('installmentInfo', currentInstallmentList)

                  self.prePayMergeState('prePayUnPayParams', { 
                    cardBin: unPayRoutingInfo.routingInfo?.bin,
                    routeId: unPayRoutingInfo.routingInfo?.routeId,
                    paymentCode: unPayRoutingInfo.routingInfo?.payMethod,
                    countryCode: unPayRoutingInfo.routingInfo?.orderCurrency || self.prePayCheckoutInfo.shipCountry,
                    cardType: unPayRoutingInfo.routingInfo?.cardType,
                    installments: newInstallments
                  })

                  self.visiblePrePay = true
                  daEventCenter.triggerNotice({
                    daId: '2-40-2'
                  })
                }
              })
              // daEventCenter.triggerNotice({
              //   daId: '1-19-1-22',
              //   extraData: {
              //     billno,
              //     error_code: result.info?.error_code || result?.status || '',
              //     isDetailPage: location.pathname.includes('/detail/')
              //   }
              // })
            }
          }
        })
      }
      if (self.ddcFormParams.formActionUrl && self.ddcFormParams.jwt) {
        const startPayTime = Date.now()
        self.initPayJsonWebToken(self.ddcFormParams)
        self.sendDdc({
          cardBin: self.ddcFormParams?.bin || '',
          callback: (sessionId = '') => {
            params.sessionId = sessionId
            self.prePayMergeState('clickContinueResultInfo', {
              pay_response_time: parseInt((Date.now() - startPayTime) / 1000)
            })
            handleTokenPayFunc(params)
          }
        })
      } else {
        handleTokenPayFunc(params)
      }
    },
    handlePrePayActiveClose () {
      if (!this.prePayCheckoutInfo?.billno) return
      const { langPath } = gbCommonInfo || {}
      window.location.href = `${langPath}/user/orders/detail/${this.prePayCheckoutInfo?.billno}`
    },
    checkResetPrePayCheckoutInfo () {
      if (!this.isUsePrePayForCheckout) {
        this.assignState({ prePayForCheckoutReady: false })
        this.resetPrePayCheckoutInfo()
      }
    }
  }
}
</script>

<style lang="less">
.mshe-wrapper {
  padding: 0.4rem;
}
.mshe-top-pad {
  padding-top: 1.17rem;
}
.mshe-top-pad {
  .font-dpr(28px);
  .mshe-flexbetween:last-child {
    border-bottom: 0;
  }
}
.mshe-flexbetween {
  .flexbox();
  .space-between();
}
.mcheo-title {
  text-transform: capitalize;
  color: #222;
  background: #fff;
  padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
  font-weight: 700;
  padding-top: 0.32rem !important; /* stylelint-disable-line declaration-no-important */
  & when (@IS_RW) {
    font-weight: 400;
  }
  .text-error {
    color: @sui_color_unusual;
    .margin-l(0.2rem);
    text-transform: initial;
  }
}
.mcheo-stand {
  background: #fff;
  margin-top: 16/75rem;
  .padding-l(0.32rem);
  li {
    padding: 0.2rem;
    .padding-r(0.32rem);
    .padding-l(0);
    .align-center();
    .border-dpr(border-bottom,2px,#e5e5e5);
    position: relative;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
}

.mshe-hor-val {
  .flexbox();
  .align-center();
  .pack-center();
}
.use-free-tip {
  color: @sui_color_promo;
  background: @sui_color_promo_bg;
  display: inline-block;
  padding: 0.05rem 0.106rem;
  background: #fff6f3;
  .font-dpr(24px);
  font-weight: 400;
}
.freez-drawer {
  .S-drawer__container {
    min-height: 436/75rem;
  }
}

.checkout-wrap-effiency {
  .mcheo-payment,
  .mcheo-address {
    margin-top: 16/75rem;
  }
  .support-info-module {
    padding-top: 16/75rem;
  }
}
.c-checkout-page code {
  font-family: unset;
}

// 订单明细抽屉的遮罩
.price-detail-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.mask-enter,
.mask-enter-active {
  animation: mask-fade-in 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.mask-leave-leave,
.mask-leave-active {
  animation: mask-fade-out 0.25s cubic-bezier(0, 0, 0.2, 1) forwards;
}

@keyframes mask-fade-in {
  0% {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes mask-fade-out {
  100% {
    background: rgba(0, 0, 0, 0);
  }
}

.pre-pay-price {
  color: #FA6338;
  font-size: 14px;
  font-family: SF Pro;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

.prePay-price-detail {
  .price-detail__item {
    line-height: 1;
  }

  .price-item-details__item {
    line-height: 1;
  }
}
</style>
