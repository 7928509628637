import Vue, { ref, onMounted, onBeforeUnmount, reactive, readonly, provide, set, toRef, watch, computed } from 'vue'
import { nextIdle, getOrderAttributesList, debuggerLog } from '../helpers'
import { fetchPrePayInfo, queryPrePayDdcParams, fetchInstallmentInfo, fetchPrePayRoutingForCheckout, queryUnPayPreRoutingInfo } from '../fetch'
import { useEventBus } from './useEventBus'
import { PRE_PAY_INJECTION_KEY } from '../constant'
import { getDefaultPrePayUnPayParams } from '../defaultState'
import { cloneDeep } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Toast as $toast } from '@shein/sui-mobile'

daEventCenter.addSubscriber({ modulecode: '2-41' })

export default ({
  scene = ''
}) => {
  const prePayModalRef = ref()
  const visiblePrePay = ref(false)

  // const DEFAULT_VIRTUAL_TOKEN_INFO = {
  //   id: VIRTUAL_TOKEN_ID,
  //   card_no: '',
  //   web_logo: '',
  //   app_logo: '',
  //   card_type: '',
  //   last_four_no: '',
  //   card_bin: '',
  //   virtual_cvv: '',
  //   virtual_year: '',
  //   virtual_month: '',
  //   virtual_cpf: '',
  //   virtual_card_holder_name: '',
  //   virtual_remember_card: 0,
  //   virtual_card_no: '',
  //   virtual_installments: 0
  // }

  const prePayEventBus = {
    // 通知更新计价接口
    updateCacuTotal: useEventBus('update-cacu-total'),
    // 通知计价接口调用完成
    cacuTotalFinish: useEventBus('cacu-total-finish'),
    // 通知创建订单
    createOrder: useEventBus('create-order'),
    // 通知显示或隐藏默认卡bin优惠信息
    showDefaultCardBinDiscount: useEventBus('show-default-card-bin-discount'),
    // 重置表单数据
    resetFormFields: useEventBus('reset-form-fields')
  }

  const updateState = (key, payload) => {
    if (Vue.version?.startsWith?.('3')) {
      rootStore[key] = payload
    } else {
      // Vue2.x
      set?.(rootStore, key, payload)
    }
    debuggerLog('updateState>>>', key, cloneDeep(payload))
    debuggerLog('rootStore>>>>', cloneDeep(rootStore))
  }

  const mergeState = (key, payload) => {
    if (Vue.version?.startsWith?.('3')) {
      rootStore[key] = { ...rootStore[key], ...payload }
    } else {
      // Vue2.x
      set?.(rootStore, key, { ...rootStore[key], ...payload })
    }
    debuggerLog('mergeState>>>>>>', key, cloneDeep(payload))
    debuggerLog('rootStore>>>>', cloneDeep(rootStore))
  }

  const handleQueryUnPayPreRoutingInfo = async ({
    bin = '',
    billno = '',
    routeCode = '',
    orderCurrency = '',
    countryCode = '',
    paymentMethod = '',
    queryCardBin = 1
  } = {}) => {
    const queryCardInstallment = routeCode === 'routepay-cardinstallment' ? 1 : 0
    return queryUnPayPreRoutingInfo({
      bin,
      billno,
      routeCode,
      orderCurrency,
      countryCode,
      paymentMethod,
      queryCardBin,
      queryCardInstallment
    }).then(data => {
      return {
        cardBinDiscountInfo: data?.cardBinDiscountInfo || {},
        installments: data?.installments || [],
        routingInfo: data?.info || {}
      }
    })
  }

  // 转换成单后的卡bin信息数据格式为成单前的
  const changeCardBinDiscountInfoData = (bin, cardBinDiscountInfo = {}) => {
    let tempCardBinDiscountInfo = {}
    if (Object.keys(cardBinDiscountInfo).length > 0 && cardBinDiscountInfo?.card_bin_discount_info?.discount_type != null) {
      tempCardBinDiscountInfo = {
        all_card_bin_discount_map: {
          [bin]: {
            card_bin: bin,
            max_amount: cardBinDiscountInfo?.card_bin_discount_info?.max_price,
            min_amount: cardBinDiscountInfo?.card_bin_discount_info?.min_price,
            min_random_amount: cardBinDiscountInfo?.card_bin_discount_info?.min_random_price,
            max_random_amount: cardBinDiscountInfo?.card_bin_discount_info?.max_random_price,
            total_discount_amount: cardBinDiscountInfo?.card_pin_total_discount_price,
            type: cardBinDiscountInfo?.card_bin_discount_info?.discount_type,
            issuer_bank_name: cardBinDiscountInfo?.card_bin_discount_info?.issuer_bank_name,
          }
        }
      }
      // console.log('unPayRoutingInfo.cardBinDiscountInfo >>>>', tempCardBinDiscountInfo)
    }
    rootStore.mergeState('prePayCheckoutInfo', { 
      cardBinDiscountInfo: tempCardBinDiscountInfo
    })
  }

  // 重置表单信息
  const resetPrePayCheckoutInfo = () => {
    rootStore.updateState('prePayUnPayParams', getDefaultPrePayUnPayParams())
    rootStore.mergeState?.('prePayCheckoutInfo', { cardBinDiscountInfo: {}, mallCaculateInfo: {}, routeCode: '' })
    rootStore.updateState('cardBinRoutingInfo', {})
    rootStore.updateState('displayCardBinDiscountInfo', {})
    // 在线支付优惠随机立减等展示状态重置
    prePayEventBus?.showDefaultCardBinDiscount?.emit?.(true)
    prePayEventBus?.resetFormFields?.emit?.()
  }

  const rootStore = reactive({
    preFetchInfo: {
      payDomainLogoList: [],
      language: {},
      abtData: {}
    },
    prePayCheckoutInfo: {
      orderAttributesList: [],
      orderCurrency: '',
      orderUsdAmount: 0,
      orderAmount: 0,
      shipCountry: '',
      shipAddressId: 0, // 用于信息对比
      virtualOrderScene: 0,
      routeCode: '',
      onlinePayDiscountInfo: {},
      cardBinDiscountInfo: {},
      mallCaculateInfo: {},
      bin_discount_list: [],
      bin_discount_info: {},
      isAutoRenewal: false, // TODO 是否选择了自动续费产品包
      taxNumber: '', // 税号，用于自动填充
      billno: '' // 订单号，用于单后场景处理
    },
    cardBinRoutingInfo: {},
    displayCardBinDiscountInfo: {}, // 展示在卡号输入框下方的卡bin优惠信息
    ddcFormParams: {
      jwt: '',
      formActionUrl: '',
      bin: ''
    },
    prePayUnPayParams: { ...getDefaultPrePayUnPayParams() },
    installmentInfo: [],
    prePayFailGuide: {
      visible: false,
      failText: '',
      type: '',
      hasGuide: true,
      closeCb: () => {},
      confirmCb: () => {}
    },
    prePayBinFreezeFailConfig: {
      visible: false,
      confirmCb: () => {},
      cancelCb: () => {}
    },
    orderInfo: {},
    cardBinFreezeParams: {
      billno: '',
      bin: '', // 新卡取用户输入的卡号前八位，卡token取接口返回的bin
      payment_code_unique: '',
      v_tips: 0 // 前端展示了卡bin优惠信息，冻结失败则需要弹窗提示
    },
    pageReadyTime: 0, // 页面加载完成时间
    // 埋点属性
    clickContinueResultInfo: {
      failure_reason: '', // 失败原因 属性值格式为：{code:xxx,result:xxx,type:xxx}，其中result（哪里出错）和type（什么错误类型）两部分的规则如下： 当卡号错误时，result的值为card_num
      failure_type: '', // 失败类型 front_end/rear_end
      instalment_num: '',
      is_remember: '',
      order_id_list: '', // 每个子订单号之间用 隔开
      order_no: '',
      payment_method: '',
      prime_deduction: '', // 付费会员抵扣金额
      product_type: '', // 产品类型 单独购付费会员发membership； 单独购超省卡发sheinsaver； 其他订单类型发commontype
      scene: '', // 场景类型 新卡上报new，非新卡上报token
      status: '',
      pay_response_time: '',
      stay_time: '', // 单位是“秒” 记录从进入卡支付页面到点击continue按钮的时长
      token_id: '', // token_id
      uorder_id: '', // 关联订单号 
      is_cvvnolimit: '0', // 是否需要cvv 1 需要 0 不需要
    },
    // virtualTokenInfo: { ...DEFAULT_VIRTUAL_TOKEN_INFO },
    scene,
    updateState,
    mergeState,
    handleQueryUnPayPreRoutingInfo,
    prePayEventBus,
    changeCardBinDiscountInfoData
  })

  const shouldShowBinDiscountFreezeFailTips = computed(() => {
    const cardBinDiscountInfo = rootStore.prePayCheckoutInfo?.cardBinDiscountInfo?.all_card_bin_discount_map?.[rootStore.prePayUnPayParams?.cardBin] || {}
    return rootStore.preFetchInfo?.abtData?.BinRandomDiscount?.param?.BinRandomShow === 'Show' &&
      (cardBinDiscountInfo?.total_discount_amount > 0 || cardBinDiscountInfo.type != null)
  })

  const updatePrePaySetCheckoutInfo = (data) => {
    debuggerLog('updatePrePaySetCheckoutInfo==data===', cloneDeep(data))
    mergeState('prePayCheckoutInfo', data)
  }

  const formatPrePayCheckoutInfo = ({
    mallCaculateInfo = {}, 
    defaultAddress = {},
    normalGoodsList = [],
    usedPrimeProductInfo = {},
    usedXtraProductInfo = {},
    routeCode = '',
    bin_discount_list = [],
    bin_discount_info = {}
  } = {}) => {
    let orderAttributesList = [] // 订单主体id+业务模式集合 需去重
    let orderCurrency = '' // 下单币种
    let orderUsdAmount = 0 // 美金币种应付金额
    let orderAmount = 0 // 下单币种应付金额	计算价格最终的实付金额（扣除券、积分、礼品卡、钱包等虚拟资产和活动的整车价格）
    let shipCountry = '' // 下单页的地址收货国家
    let shipAddressId = 0 // 下单页的地址id
    let virtualOrderScene = 0 // 虚拟商品（付费会员or超省） 1-单独购 2-随单购 不传或其他值-代表无虚拟商品

    const { grandTotalPrice = {}, orderCurrency: mallCaculateInfoOrderCurrency = {}, onlinePayDiscountInfo = {}, cardBinDiscountInfo = {} } = mallCaculateInfo || {}

    orderUsdAmount = grandTotalPrice?.usdAmount || 0
    orderAmount = grandTotalPrice?.amount || 0
    orderCurrency = mallCaculateInfoOrderCurrency?.code || ''
    shipCountry = defaultAddress?.value || ''
    shipAddressId = defaultAddress?.addressId || ''
    orderAttributesList = getOrderAttributesList({ normalGoodsList, usedPrimeProductInfo, usedXtraProductInfo })
    if (Object.keys(usedPrimeProductInfo).length || Object.keys(usedXtraProductInfo).length) {
      virtualOrderScene = 2
    }
    
    return {
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      orderAmount,
      shipCountry,
      shipAddressId,
      virtualOrderScene,
      routeCode,
      onlinePayDiscountInfo,
      cardBinDiscountInfo: cardBinDiscountInfo || {},
      bin_discount_list,
      bin_discount_info,
      mallCaculateInfo,
      taxNumber: defaultAddress?.taxNumber || ''
    }
  }

  const  getInstallmentInfo = async ({
    routeCode,
    shipCountry,
    orderCurrency,
    orderAmount
  } = {}, { skipUpdateInstallmentInfo = false } = {}) => {
    const res = await fetchInstallmentInfo({
      paymentCode: routeCode || rootStore.prePayCheckoutInfo.routeCode,
      countryCode: shipCountry || rootStore.prePayCheckoutInfo.shipCountry,
      orderCurrency: orderCurrency || rootStore.prePayCheckoutInfo.orderCurrency,
      orderAmount: orderAmount || rootStore.prePayCheckoutInfo.orderAmount
    })
    const installments = res?.info?.installments || [
      {
        stage_num: 1,
        rate: 0,
        installment_amount: rootStore.prePayCheckoutInfo.orderAmount,
        installment_fee_numeric: 0
      }
    ]
    !skipUpdateInstallmentInfo && rootStore.updateState('installmentInfo', installments)

    return installments || []
  }

  // const appendVirtualTokenInfo = (data) => {
  //   rootStore.mergeState('virtualTokenInfo', data)
  // }
  // const resetVirtualTokenInfo = () => {
  //   rootStore.mergeState('virtualTokenInfo', { ...DEFAULT_VIRTUAL_TOKEN_INFO })
  // }

  const handlePrePayForCheckout = () => {
    const formValid = prePayModalRef.value.formValidator()
    let installmentValid = true
    if (rootStore.prePayCheckoutInfo.routeCode === 'routepay-cardinstallment') {
      installmentValid = prePayModalRef.value.installmentValidator()
    }
    if (formValid.result && !installmentValid) {
      $toast(rootStore.preFetchInfo.language?.SHEIN_KEY_PWA_24897)
      rootStore?.mergeState('prePayUnPayParams', {
        ...rootStore?.prePayUnPayParams,
        isSelectedInstallment: false
      })
      prePayModalRef.value.scrollToInstallment()
    } else {
      rootStore?.mergeState('prePayUnPayParams', {
        ...rootStore?.prePayUnPayParams,
        isSelectedInstallment: true
      })
    }
    if (formValid.result && installmentValid) {
      visiblePrePay.value = false
      if (rootStore.prePayCheckoutInfo?.billno) {
        rootStore.updateState('cardBinFreezeParams', {
          billno: rootStore.prePayCheckoutInfo.billno,
          bin: rootStore.prePayUnPayParams?.cardNumber?.substring?.(0, 8) || rootStore.prePayUnPayParams?.cardBin || '',
          payment_code_unique: rootStore.prePayCheckoutInfo?.routeCode,
          v_tips: shouldShowBinDiscountFreezeFailTips.value ? 1 : 0
        })
      }
      prePayEventBus.createOrder.emit()
      debuggerLog('handlePrePayForCheckout===emit==', cloneDeep(rootStore.prePayUnPayParams))
    } else {
      prePayEventBus.createOrder.emit({ prePayCardVaildFail: true, failReasonResults: formValid.failReasonResults })
    }
  }

  const fetchCheckoutMallCaculateInfo = async ({ cardBin = '' } = {}) => {
    return new Promise((resolve, reject) => {
      let fetchStatus = false
      prePayEventBus.updateCacuTotal.emit({
        data: {
          selected_bin: cardBin,
          need_select: 1,
          bins: [cardBin]
        },
        cb: data => {
          fetchStatus = true
          resolve(data)
        }
      })
      setTimeout(() => {
        fetchStatus || reject()
      }, 30000)
    })
  }

  const getPrePayRoutingInfo = ({ 
    orderAttributesList,
    orderCurrency,
    orderUsdAmount,
    orderAmount,
    shipCountry,
    virtualOrderScene,
    routeCode,
    bin = ''
  } = {}) => {

    const params = {
      bin,
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      orderAmount,
      shipCountry,
      routeCode
    }
    if (virtualOrderScene) params.virtualOrderScene = virtualOrderScene
    return fetchPrePayRoutingForCheckout(params).then(data => {
      const routingInfo = data?.info || {}
      return routingInfo
    })
  }

  // 校验必要参数是否有变化
  const checkPrePayParamsChange = async ({
    currentDefaultAddress = {},
    normalGoodsList = [],
    usedPrimeProductInfo = {},
    usedXtraProductInfo = {},
    routeCode = '',
  }) => {
    // 订单及商品维度 地址（地址id）、业务模式、companyid、总价
    // 支付维度 卡号、有效期、cvv、持卡人姓名、税号、分期信息

    const currentMallCaculateInfo = await fetchCheckoutMallCaculateInfo({ cardBin: rootStore.prePayUnPayParams.cardBin || '411111' })

    debuggerLog('currentMallCaculateInfo==', currentMallCaculateInfo)
    const mallCaculateInfo = currentMallCaculateInfo?.info || {}
    const {
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      orderAmount,
      shipCountry,
      shipAddressId,
      virtualOrderScene
    } = formatPrePayCheckoutInfo({
      mallCaculateInfo,
      defaultAddress: currentDefaultAddress,
      normalGoodsList,
      usedPrimeProductInfo,
      usedXtraProductInfo,
    })

    const [
      { value: currentRoutingInfo = {} },
      { value: currentInstallmentList = [] }
    ] = await Promise.allSettled([
      getPrePayRoutingInfo({
        orderAttributesList,
        orderCurrency,
        orderUsdAmount,
        orderAmount,
        shipCountry,
        virtualOrderScene,
        routeCode,
        bin: rootStore.prePayUnPayParams?.cardNumber?.substring?.(0, 8) || rootStore.prePayUnPayParams?.cardBin || ''
      }),
      rootStore.prePayUnPayParams.installments ? getInstallmentInfo({
        routeCode,
        shipCountry,
        orderCurrency,
        orderAmount
      }, { skipUpdateInstallmentInfo: true }) : Promise.resolve([])
    ])

    let checkResult = false

    const isEqualAttributesList = (nowList, oldList) => {
      if (nowList.length !== oldList.length) return false
      const nowHash = nowList.reduce((pre, cur) => ({ ...pre, [`${cur.businessModel}-${cur.companyId}`]: true }), {})
      return oldList.every(item => nowHash[`${item.businessModel}-${item.companyId}`])
    }
    // 校验商品属性信息是否有变化
    if (!isEqualAttributesList(orderAttributesList, rootStore.prePayCheckoutInfo.orderAttributesList)) {
      debuggerLog('校验商品属性信息是否有变化==')
      checkResult = true
    }
    // 订单币种是否有变化
    if (orderCurrency !== rootStore.prePayCheckoutInfo.orderCurrency) {
      debuggerLog('订单币种是否有变化==', orderCurrency, rootStore.prePayCheckoutInfo.orderCurrency)
      checkResult = true
    }
    // 金额是否有变化
    if (orderUsdAmount !== rootStore.prePayCheckoutInfo.orderUsdAmount) {
      debuggerLog('金额是否有变化==', orderUsdAmount, rootStore.prePayCheckoutInfo.orderUsdAmount)
      checkResult = true
    }
    // 收货地址（id）是否有变化
    if (shipAddressId !== rootStore.prePayCheckoutInfo.shipAddressId) {
      debuggerLog('收货地址（id）是否有变化', shipAddressId, rootStore.prePayCheckoutInfo.shipAddressId)
      checkResult = true
    }
    // if (shipCountry !== rootStore.prePayCheckoutInfo.shipCountry) return true
    
    // 选择的支付方式是否有变化
    if (routeCode !== rootStore.prePayCheckoutInfo.routeCode) {
      debuggerLog('选择的支付方式是否有变化==', routeCode, rootStore.prePayCheckoutInfo.routeCode)
      checkResult = true
    }
    // 路由返回的cardBin是否有变化
    if (currentRoutingInfo.bin !== rootStore.prePayUnPayParams.cardBin) {
      debuggerLog('路由返回的cardBin是否有变化==', currentRoutingInfo.bin, rootStore.prePayUnPayParams.cardBin)
      checkResult = true
    }
    // 路由到的routeId是否有变化
    // if (currentRoutingInfo.routeId !== rootStore.prePayUnPayParams.routeId) {
    //   debuggerLog('路由到的routeId是否有变化==', currentRoutingInfo.routeId, rootStore.prePayUnPayParams.routeId)
    //   checkResult = true
    // }
    // 路由到的支付方式是否有变化
    if (currentRoutingInfo.payMethod !== rootStore.prePayUnPayParams.paymentCode) {
      debuggerLog('路由到的支付方式是否有变化==', currentRoutingInfo.payMethod, rootStore.prePayUnPayParams.paymentCode)
      checkResult = true
    }
    // 需要税号是否有变化
    if (currentRoutingInfo.isDocument !== rootStore.cardBinRoutingInfo.isDocument) {
      debuggerLog('需要税号是否有变化==', currentRoutingInfo.isDocument, rootStore.cardBinRoutingInfo.isDocument)
      checkResult = true
    }
    // 需要持卡人姓名是否有变化
    if (currentRoutingInfo.isCardHoldName !== rootStore.cardBinRoutingInfo.isCardHoldName) {
      debuggerLog('需要持卡人姓名是否有变化==', currentRoutingInfo.isCardHoldName, rootStore.cardBinRoutingInfo.isCardHoldName)
      checkResult = true
    }
    // 原选择的分期数是否在新的分期信息列表中
    // 新返回的分期列表与原列表是否有变化
    if (currentInstallmentList.length !== rootStore.installmentInfo?.length) {
      debuggerLog('新返回的分期列表与原列表是否有变化==', currentInstallmentList.length, rootStore.installmentInfo?.length)
      checkResult = true
    }
    for (let i = 0; i < (rootStore.installmentInfo?.length || 0); i++) {
      const item = rootStore.installmentInfo?.[i] || {}
      if (item.stage_num !== currentInstallmentList?.[i]?.stage_num) {
        debuggerLog('新返回的分期列表与原列表是否有变化==222====', item.stage_num, currentInstallmentList?.[i]?.stage_num)
        checkResult = true
        break
      }
    }
    // 原选择的分期数是否在新的分期信息列表中
    // if (!currentInstallmentList.find(v => v.stage_num === rootStore.prePayUnPayParams.installments)) checkResult = true

    // 判断计价是否返回卡bin优惠
    if (mallCaculateInfo?.cardBinDiscountInfo?.all_card_bin_discount_map && Object.keys(mallCaculateInfo?.cardBinDiscountInfo?.all_card_bin_discount_map || {}).length) checkResult = true

    debuggerLog('checkPrePayParamsChange==checkResult==', checkResult, mallCaculateInfo?.cardBinDiscountInfo?.all_card_bin_discount_map)

    // if (!currentInstallmentList.find(v => v.stage_num === rootStore.prePayUnPayParams.installments)) checkResult = true
    return {
      checkResult,
      mallCaculateInfo,
      cardBinRoutingInfo: currentRoutingInfo,
      installments: currentInstallmentList
    }
  }

  const prePayLanguage = computed(() => rootStore.preFetchInfo.language)

  const reportClickContinueResult = (data) => {
    daEventCenter?.triggerNotice?.({
      daId: '2-41-8',
      extraData: {
        is_need_cvv: data?.is_need_cvv || '-',
        prime_deduction: data?.prime_deduction || ''
      }
    })
    daEventCenter?.triggerNotice?.({
      daId: '2-41-9',
      extraData: data
    })
  }

  provide(PRE_PAY_INJECTION_KEY, readonly(rootStore))

  watch(() => visiblePrePay.value, (show) => {
    if (show) {
      daEventCenter.triggerNotice({
        daId: '2-41-1'
      })
    }
  })

  onMounted(async () => {
    rootStore.pageReadyTime = window.SaPageInfo?.start_time || Date.now()
    console.time('nextIdle')
    await nextIdle()
    console.timeEnd('nextIdle')
    console.time('fetchPrePayInfo')
    const data = await fetchPrePayInfo()
    console.timeEnd('fetchPrePayInfo')
    rootStore.preFetchInfo = data

    // 计价接口调用完成更新接口信息
    prePayEventBus.cacuTotalFinish?.on?.(() => {
      queryPrePayDdcParams({
        orderDetails: rootStore.prePayCheckoutInfo.orderAttributesList,
        countryCode: rootStore.prePayCheckoutInfo.orderCurrency,
        routeCode: rootStore.prePayCheckoutInfo.routeCode
      }).then(data => {
        const { jwt, formActionUrl } = data?.info || {}
        // debuggerLog('queryPrePayDdcParams===data==||==', cloneDeep(data))
        rootStore.mergeState('ddcFormParams', { jwt, formActionUrl })
      })
    })
  })

  onBeforeUnmount(() => {
    provide(PRE_PAY_INJECTION_KEY, null)
  })

  return {
    visiblePrePay,
    prePayModalRef,
    ddcFormParams: toRef(rootStore, 'ddcFormParams'),
    prePayUnPayParams: toRef(rootStore, 'prePayUnPayParams'),
    prePayFailGuide: toRef(rootStore, 'prePayFailGuide'),
    prePayCheckoutInfo: toRef(rootStore, 'prePayCheckoutInfo'),
    prePayOrderInfo: toRef(rootStore, 'orderInfo'),
    cardBinFreezeParams: toRef(rootStore, 'cardBinFreezeParams'),
    prePayBinFreezeFailConfig: toRef(rootStore, 'prePayBinFreezeFailConfig'),
    pageReadyTime: toRef(rootStore, 'pageReadyTime'),
    clickContinueResultInfo: toRef(rootStore, 'clickContinueResultInfo'),
    prePayLanguage,
    // virtualTokenInfo: toRef(rootStore, 'virtualTokenInfo'),
    formatPrePayCheckoutInfo,
    updatePrePaySetCheckoutInfo,
    prePayEventBus,
    handlePrePayForCheckout,
    getInstallmentInfo,
    prePayMergeState: rootStore.mergeState,
    prePayUpdateState: rootStore.updateState,
    checkPrePayParamsChange,
    handleQueryUnPayPreRoutingInfo,
    changeCardBinDiscountInfoData,
    resetPrePayCheckoutInfo,
    reportClickContinueResult
    // appendVirtualTokenInfo,
    // resetVirtualTokenInfo
  }
}
