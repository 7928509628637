<template>
  <div class="checkout-title">
    <!-- 手动触发滚动标题，方案show、show1 -->
    <HeaderTitleOperateShow
      v-if="isShowOperatScrollTitle"
      :isShowTwoRowsTitle="showNewTitle === 'Show1'"
    />
    <!-- 自动循环滚动标题，方案show2 -->
    <HeaderTitleAutoShow
      v-else-if="isShowAutoScrollTitle"
    />
    <!-- 默认标题，无任何动作 -->
    <h2
      v-else
      class="S-title-nav__content-title checkout-title__default-text"
    >
      {{ language.SHEIN_KEY_PWA_15245 }}
    </h2>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import HeaderTitleOperateShow from './HeaderTitleOperateShow.vue'
import HeaderTitleAutoShow from './HeaderTitleAutoShow.vue'

export default {
  name: 'CheckoutHeaderTitle',

  components: {
    HeaderTitleOperateShow,
    HeaderTitleAutoShow
  },
  data: function () {
    return {
    }
  },
  
  computed: {
    ...mapState('checkout', [ 'language' ]),
    ...mapGetters('checkout', ['userBehaviorAbt', 'showShippingInHeaderTitleByAbt']),
    IS_RW() {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW
    },
    showNewTitle() {
      return this.userBehaviorAbt.CheckoutHeadlineShow
    },
    isShowOperatScrollTitle() {
      return !this.IS_RW && ['Show', 'Show1'].includes(this.showNewTitle) && ['type_A', 'type_B', 'type_C'].includes(this.showShippingInHeaderTitleByAbt)
    },
    isShowAutoScrollTitle() {
      return !this.IS_RW && this.showNewTitle === 'Show2' && ['type_A', 'type_B', 'type_C'].includes(this.showShippingInHeaderTitleByAbt)
    },
  },
}
</script>
<style lang="less" scoped>
.checkout-title {
  width: 100%;
  height: 100%;
  &__default-text {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
</style>
